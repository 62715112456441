export const describe = {
  title: "Dummy",
  type: "object",
  label: "Tabla Dummy",
  properties: {
    id: {
      title: "id",
      required: false,
      type: "integer",
      label: "Id",
      hide: false,
      default: 18,
    },
    bool_field: {
      title: "bool_field",
      required: true,
      type: "bool",
      label: "Campo Booleano",
      hide: false,
      default: true,
    },
    integer_field: {
      title: "integer_field",
      required: true,
      type: "integer",
      label: "Campo Numero Entero",
      hide: false,
      default: 18,
    },
    date_field: {
      title: "date_field",
      required: true,
      type: "date",
      label: "Campo de Fecha",
      hide: false,
      default: "2022-05-23",
    },
    float_field: {
      title: "float_field",
      required: true,
      type: "float",
      label: "Campo Numero Flotante",
      hide: false,
      default: 18.83,
    },
    string_field: {
      title: "Id",
      required: false,
      type: "string",
      maxLength: 25,
      label: "Campo Cadena Caracteres",
      hide: false,
      default: "campo de caracteres",
    },
    string_email_field: {
      title: "string_email_field",
      required: false,
      type: "string",
      maxLength: 25,
      label: "Campo de Email",
      hide: false,
      default: "default@gmail.com",
    },
    password_field: {
      title: "password_field",
      required: true,
      type: "string",
      maxLength: 25,
      label: "Campo de Contraseña",
      hide: false,
      default: "Pass$w0rd1234",
    },
    text_field: {
      title: "text_field",
      required: false,
      type: "string",
      label: "Campo de textarea",
      hide: false,
      default: "TextArea",
    },
    enum_field: {
      title: "enum_field",
      required: true,
      type: "enum",
      label: "Campo de enum",
      hide: false,
      enum: ["enum1", "enum2", "enum3"],
      default: "",
    },
    hide_field: {
      title: "hide_field",
      required: false,
      type: "integer",
      label: "Campo Oculto",
      hide: true,
      default: 10,
    },
    dummy_to_one_id: {
      title: "dummy_to_one_id",
      required: false,
      type: "integer",
      label: "Llave Foranea",
      hide: true,
    },
  },
  foreign_models: [
    {
      name: "dummy_to_one_id",
      model: "dummy_to_one",
      _in_db: "dummy_to_one",
    },
    {
      intermediate: "dummmym2m",
      model: "dummy_two",
      _in_db: "dummmy_m2_ms",
      field_model: "dummy_id",
      field_model_two: "dummy2_id",
    },
  ],
};

export const types = [
  {
    type: "textarea",
    value: "textarea",
  },
  {
    type: "str",
    value: "text",
  },
  {
    type: "string",
    value: "text",
  },
  {
    type: "email",
    value: "text",
  },
  {
    type: "password",
    value: "text",
  },
  {
    type: "int",
    value: "number",
  },
  {
    type: "integer",
    value: "number",
  },
  {
    type: "bool",
    value: "checkbox",
  },
  {
    type: "datetime",
    value: "date",
  },
  {
    type: "date",
    value: "date",
  },
  {
    type: "enum",
    value: "select",
  },
  {
    type: "float",
    value: "float",
  },
];
