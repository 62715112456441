import {post} from "@/services/api";

export const handleSendPhone = async (payload: object) => {
    return await post({
        url: "sms-code", payload: payload
    });
};

export const handleSendPassword = async (payload: object) => {
    return await post({
        url: "change-password", payload: payload
    });
};

