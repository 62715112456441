import { post } from "@/services/api";

export const handleLogin = async (payload: object) => {
  return await post({
    url: "login",
    payload: payload,
    config: {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  });
};
export const handleLogout = async () => {
  return await post({
    url: "logout",
  });
};
