import { MODEL } from "@/utils/constants";
import { handleSearchReadModel } from "@/services/generic";
import { useRegister } from "@/composables/useRegister";
import { ILocation } from "@/interfaces/locale/ILocation";

export const handleLocationData = async (): Promise<ILocation[]> => {
  const { user } = useRegister();

  try {
    const payload = {
      model: MODEL.LOCATION,
      fields: ["id", "name", "address","country_id", "phone_number", "email", "", "code", "local_type"],
    };

    const filter = [
      {
        field: "entity_id",
        op: "==",
        value: user.value.entity_id,
      },
    ];
    const data = await handleSearchReadModel(payload, filter);
    return data.items as ILocation[];
  } catch (err) {
    console.error(err);
    return [];
  }
};
