import { useRecordStore, RecordStore } from "@/stores/record";
import { storeToRefs } from "pinia";
import { useAudio } from "./useAudio";
import { VoiceRecorder, RecordingData } from "capacitor-voice-recorder";
import { useRegister } from "./useRegister";
import { NOTIFICATION_TYPE } from "@/utils/constants";
export default function useRecord() {
  const recordStore: RecordStore = useRecordStore();
  const {
    recording,
    listening,
    nameUser,
    playing,
    userId,
    mode,
    showSnackBar,
  } = storeToRefs(recordStore);
  const { startRecording, stopRecording, sendAudio } = useAudio();
  const { user } = useRegister();

  const toggleRecord = async (info: any, options?: any) => {
    recordStore.toggleRecord(info);

    if (info.userId) {
      recordStore.setRecipientUser({
        mode: info.mode,
        userId: info.userId,
      });
    }

    if (recording.value) {
      await startRecording();
      if (!options?.button) {
        record();
      }
    } else {
      await stopRecording();
      if (!options?.button) {
        stop();
      }
    }
  };
  const record = () => {
    VoiceRecorder.startRecording().catch((error) =>
      console.log("record", error)
    );
  };

  const stop = () => {
    VoiceRecorder.stopRecording()
      .then(async (result: RecordingData) => {

        const payload = {
          location_id: user.value.location_id?.toString(),
          file: result.value.recordDataBase64,
          duration: result.value.msDuration
        };

        const payloadUser = {
          location_id: user.value.location_id?.toString(),
          user_id:
            recordStore.mode == NOTIFICATION_TYPE.USER
              ? recordStore.userId.toString()
              : undefined,
          file: result.value.recordDataBase64,
          duration: result.value.msDuration
        };

        await sendAudio(
          recordStore.mode == NOTIFICATION_TYPE.USER ? payloadUser : payload
        );

        if (recordStore.mode == NOTIFICATION_TYPE.USER) {
          recordStore.setRecipientUser({
            mode: NOTIFICATION_TYPE.LOCATION,
            userId: 0,
          });
        }
      })
      .catch((error) => console.log("stop", error));
  };
  return {
    nameUser,
    recording,
    listening,
    playing,
    userId,
    mode,
    showSnackBar,
    toggleRecord,
    toggleListen: recordStore.toggleListen,
    togglePlay: recordStore.togglePlay,
  };
}
