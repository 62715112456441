export const numberOnly = (e: any) => {
  if (
    [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
    // Allow: Ctrl+A
    (e.keyCode === 65 && e.ctrlKey === true) ||
    // Allow: Ctrl+C
    (e.keyCode === 67 && e.ctrlKey === true) ||
    // Allow: Ctrl+X
    (e.keyCode === 88 && e.ctrlKey === true) ||
    // Allow: Ctrl+V
    (e.keyCode === 86 && e.ctrlKey === true) ||
    // Allow: home, end, left, right
    (e.keyCode >= 35 && e.keyCode <= 39)
  ) {
    return;
  }
  if (
    (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
    (e.keyCode < 96 || e.keyCode > 105)
  ) {
    e.preventDefault();
  }
};

export const onlyPasteNumber = (e: any) => {
  const clipboardData = e.clipboardData || (<any>window).clipboardData;
  const pastedData = clipboardData.getData("Text");
  const regOnlyNumber = /^\d+$/;
  if (!regOnlyNumber.test(pastedData)) {
    e.preventDefault();
  }
};

export const onlyPasteFloat = (e: any) => {
  const clipboardData = e.clipboardData || (<any>window).clipboardData;
  const pastedData = clipboardData.getData("Text");
  const regOnlyFloat = /^\d*\.{1}\d*$/;
  if (!regOnlyFloat.test(pastedData)) {
    e.preventDefault();
  }
};

export const floatOnly = (e: any) => {
  if (
    [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
    // Allow: Ctrl+A
    (e.keyCode === 65 && e.ctrlKey === true) ||
    // Allow: Ctrl+C
    (e.keyCode === 67 && e.ctrlKey === true) ||
    // Allow: Ctrl+X
    (e.keyCode === 88 && e.ctrlKey === true) ||
    // Allow: Ctrl+V
    (e.keyCode === 86 && e.ctrlKey === true) ||
    // Allow: .
    e.keyCode === 110 ||
    e.keyCode === 190 ||
    // Allow: home, end, left, right
    (e.keyCode >= 35 && e.keyCode <= 39)
  ) {
    return;
  }
  if (
    (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
    (e.keyCode < 96 || e.keyCode > 105)
  ) {
    e.preventDefault();
  }
};
