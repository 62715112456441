import {
  onlyPasteNumber,
  numberOnly,
  floatOnly,
  onlyPasteFloat,
} from "@/utils/onlyNumber";

export const numericOnly = {
  created(el: any, binding: any) {
    if (binding.value === true || binding.value === undefined) {
      el.addEventListener("paste", (e: any) => {
        return onlyPasteNumber(e);
      });

      el.addEventListener("keypress", (e: any) => {
        return numberOnly(e);
      });
      el.addEventListener("keydown", (e: any) => {
        return numberOnly(e);
      });
    }
  },

  beforeUnmount(el: any, binding: any) {
    if (binding.value) {
      el.removeEventListener("paste", null);
      el.removeEventListener("keypress", null);
      el.removeEventListener("keydown", null);
    }
  },
};

export const floatNumericOnly = {
  created(el: any, binding: any) {
    if (binding.value) {
      el.addEventListener("paste", (e: any) => {
        return onlyPasteFloat(e);
      });
      el.addEventListener("keypress", (e: any) => {
        return floatOnly(e);
      });
      el.addEventListener("keydown", (e: any) => {
        return floatOnly(e);
      });
    }
  },

  beforeUnmount(el: any, binding: any) {
    if (binding.value) {
      el.removeEventListener("paste", null);
      el.removeEventListener("keypress", null);
      el.removeEventListener("keydown", null);
    }
  },
};
