import axios from "axios";
import {toastController} from "@ionic/vue";
import router from "@/router";
import {ERROR_HANDLER_MESSAGE} from "@/utils/constants";
import useCore from "@/composables/useCore";

const errorHandler = async (err: any) => {
    if (err.response.data.detail == ERROR_HANDLER_MESSAGE.SignatureHasExpired) {
        localStorage.clear();
        await router.push({name: "login"});
    }
    if (err.response.data.detail == ERROR_HANDLER_MESSAGE.TokenNotValid) {
        localStorage.clear();
        await router.push({name: "login"});
    }
    if (err.response.data.detail == ERROR_HANDLER_MESSAGE.NotMoreLocations) {
        const { setNotMoreLocation } = useCore();
        setNotMoreLocation(true);
    }
    const toast = await toastController.create({
        message: err?.response?.data?.message || "Ocurrió un error",
        duration: 1500,
        position: "bottom",
    });

    await toast.present();
    return Promise.reject({...err});
};

const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});

api.interceptors.request.use(
    (config: any) => {
        const token = localStorage.getItem("access_token");
        if (token) config.headers["Authorization"] = `Bearer ${token}`;
        return config;
    },
    (error) => errorHandler(error)
);

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => errorHandler(error)
);

export {api};
