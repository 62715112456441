import {get} from "@/services/api";

export const handlerDashboardOwner = async () => {
    return await get({
        url: 'dashboard/owner',
        payload: {},
    });
};

export const handlerDashboardMember = async () => {
    return await get({
        url: 'dashboard/member',
        payload: {},
    });
};
