import { get } from "@/services/api";
import { MODEL, ROLE_ID } from "@/utils/constants";
import { handleSearchReadModel } from "@/services/generic";
import { useRegister } from "@/composables/useRegister";
import { ISubscription } from "@/interfaces/subscription/ISubscription";
import { handleSubscriptionPlanData } from "@/services/subscription_plan";
import { handleLocationData } from "@/services/location";
import { ILocation } from "@/interfaces/locale/ILocation";
import { ref } from "vue";
import { IUserLocation } from "@/interfaces/user_location/IUserLocation";
import { handleUserLocationData } from "@/services/user_location";
import { ITeamMember } from "@/interfaces/ITeamMember";
import { CoreStore, useCoreStore } from "@/stores/core";
import { IStatus, ISubscriptionAvailability } from "@/interfaces/ISubscriptionAvailability";

export const handleSubscriptionLocationAllow = async (): Promise<boolean> => {
  const { user } = useRegister();
  const locationAllow = ref<boolean>(false);
  try {
    const payload = {
      model: MODEL.SUBSCRIPTION,
      fields: ["id", "subscription_plan_id"],
    };
    const filter = [
      {
        field: "entity_id",
        op: "==",
        value: user.value.entity_id,
      },
    ];

    const subscriptionsData = await handleSearchReadModel(payload, filter);

    const subscriptions: ISubscription[] = subscriptionsData.items;

    //Location Data
    const locationResult: ILocation[] = await handleLocationData();
    //Validate Subscription Plan
    if (subscriptions.length > 0) {
      const subscriptionPlansData = await handleSubscriptionPlanData(
        subscriptions[0].subscription_plan_id
      );
      if (subscriptionPlansData.length > 0) {
        if (
          parseInt(<string>subscriptionPlansData[0].location_max) >
            locationResult.length ||
          subscriptionPlansData[0].location_max == "unlimited"
        ) {
          locationAllow.value = true;
        } else {
          locationAllow.value = false;
        }
      } else {
        locationAllow.value = false;
      }
    }

    return locationAllow.value;
  } catch (err) {
    console.error(err);
    return locationAllow.value;
  }
};

export const handleSubscriptionByEntity = async (): Promise<
  ISubscription | []
> => {
  const { user } = useRegister();
  try {
    const payload = {
      model: MODEL.SUBSCRIPTION,
      fields: ["id", "subscription_plan_id"],
    };
    const filter = [
      {
        field: "entity_id",
        op: "==",
        value: user.value.entity_id,
      },
    ];
    const subscriptionsData = await handleSearchReadModel(payload, filter);
    const subscriptions: ISubscription[] = subscriptionsData.items;
    return subscriptions[0];
  } catch (err) {
    return [];
  }
};

export const handleSubscriptionUserAllow = async (): Promise<boolean> => {
  const { user } = useRegister();
  const locationAllow = ref<boolean>(false);
  try {
    const payload = {
      model: MODEL.SUBSCRIPTION,
      fields: ["id", "subscription_plan_id"],
    };
    const filter = [
      {
        field: "entity_id",
        op: "==",
        value: user.value.entity_id,
      },
    ];

    const subscriptionsData = await handleSearchReadModel(payload, filter);

    const subscriptions: ISubscription[] = subscriptionsData.items;

    //User Data
    const userResult: IUserLocation[] = await handleUserLocationData();
    //Location Data
    if (subscriptions.length > 0) {
      const subscriptionPlansData = await handleSubscriptionPlanData(
        subscriptions[0].subscription_plan_id
      );
      if (subscriptionPlansData.length > 0) {
        if (subscriptionPlansData[0].user_max > userResult.length) {
          locationAllow.value = true;
        } else {
          locationAllow.value = false;
        }
      } else {
        locationAllow.value = false;
      }
    }

    return locationAllow.value;
  } catch (err) {
    console.error(err);
    return locationAllow.value;
  }
};

export const handlerUserAvailability = async () => {
  return await get({
    url: "application/users_availability",
    payload: {},
  });
};

export const getTeamMembers = async (locationId: number) => {
  try {
    const coreStore: CoreStore = useCoreStore();
    get({
      url: `team/members/${locationId}`,
      payload: {},
    }).then((data: ITeamMember) => {
      coreStore.setTeamMembers(data);
    });
  } catch (e) {
    console.error(e);
  }
};

export const getSubscriptionAvailability = async () => {
  try {
    const coreStore: CoreStore = useCoreStore();
    get({
      url: `subscription/update_availability`,
      payload: {},
    }).then((data: ISubscriptionAvailability) => {
      coreStore.setSubscriptionAvailability(data);
      // const dataNew = <ISubscriptionAvailability>{
      //   subscription_success: true,
      //   users_status: {
      //     number_users: 6,
      //     subscription_status: true,
      //     available_users: 5,
      //   },
      //   teams_status: {
      //     number_teams: 1,
      //     subscription_status: true,
      //     available_teams: 1,
      //   },
      // }
      // coreStore.setSubscriptionAvailability(dataNew);
    });
  } catch (e) {
    console.error(e);
  }
};
