import { InputType } from "@/interfaces/GenericModel";
import * as Yup from "yup";
import { types } from "@/mocks/describe";
import i18n from "@/plugins/i18n";

export const fieldRequired = i18n.global.t("rules.required");
export const fieldEmail = i18n.global.t("rules.email");
export const fieldNumber = i18n.global.t("rules.number");
export const fieldDecimal = i18n.global.t("rules.decimal");
export const fieldMax = (max: number) => i18n.global.t("rules.max", { max });
export const fieldPassword = i18n.global.t("rules.password");
export const passwordMismatch = i18n.global.t("rules.passwordMismatch");

// const validPassword =
//   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#,.$($)$\-_/])[A-Za-z\d$@$!%*?&#.,$($)$\-_/]{8,}$/;

const validPassword =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#,.$($)$\-_/])[A-Za-zñ\d$@$!%*?&#.,$($)$\-_/]{8,}$/;

export const schemaLogin = Yup.object().shape({
  phone: Yup.string().required(fieldRequired).min(6),
  password: Yup.string()
    .required(fieldRequired)
    .matches(validPassword, fieldPassword),
});

export const schemaForgotPassword = Yup.object().shape({
  phone: Yup.string().required(fieldRequired).min(6),
});

export const schemaPinCode = Yup.object().shape({
  pin: Yup.string().required(fieldRequired),
});

export const schemaPassword = Yup.object().shape({
  password: Yup.string()
    .required(fieldRequired)
    .matches(validPassword, fieldPassword),
  confirm_password: Yup.string()
    .required(fieldRequired)
    .matches(validPassword, fieldPassword)
    .oneOf([Yup.ref("password"), ""], passwordMismatch),
});

export const schemaRegister = Yup.object().shape({
  phone: Yup.string().required(fieldRequired).min(6),
  name: Yup.string().required(fieldRequired),
  last_name: Yup.string().required(fieldRequired),
  password: Yup.string()
    .required(fieldRequired)
    .matches(validPassword, fieldPassword),
  confirm_password: Yup.string()
    .required(fieldRequired)
    .matches(validPassword, fieldPassword),
  accept: Yup.bool().required(fieldRequired).isTrue(fieldRequired),
});

export const schemaRegisterOwner = Yup.object().shape({
  phone: Yup.string().required(fieldRequired).min(6),
  name: Yup.string().required(fieldRequired),
  last_name: Yup.string().required(fieldRequired),
  email: Yup.string().required(fieldRequired),
  birthday: Yup.date(),
  accept: Yup.bool().required(fieldRequired).isTrue(fieldRequired),
});

export const schemaUpdateRegisterOwner = Yup.object().shape({
  name: Yup.string().required(fieldRequired),
  last_name: Yup.string().required(fieldRequired),
  email: Yup.string().required(fieldRequired),
  birthdate: Yup.date().required(fieldRequired),
});

export const schemaUpdateRegisterManager = Yup.object().shape({
  name: Yup.string().required(fieldRequired),
  last_name: Yup.string().required(fieldRequired),
  email: Yup.string().required(fieldRequired),
});


export const schemaUpdateRegisterEmployee = Yup.object().shape({
  name: Yup.string().required(fieldRequired),
  last_name: Yup.string().required(fieldRequired),
  email: Yup.string().required(fieldRequired),
});

export const schemaRegisterEntity = Yup.object().shape({
  name: Yup.string().required(fieldRequired),
  commercial_name: Yup.string().required(fieldRequired),
  address: Yup.string().required(fieldRequired),
  vat: Yup.string().required(fieldRequired),
  city_id: Yup.string(),
  country_id: Yup.string(),
});

export const schemaRegisterEntityFinal = Yup.object().shape({
  email: Yup.string().required(fieldRequired),
  phone: Yup.string().required(fieldRequired).min(6),
});

export const schemaUpdateEntity = Yup.object().shape({
  name: Yup.string().required(fieldRequired),
  commercial_name: Yup.string().required(fieldRequired),
  address: Yup.string().required(fieldRequired),
  vat: Yup.string().required(fieldRequired),
  city_id: Yup.string(),
  country_id: Yup.string(),
  email: Yup.string().email(),
  phone: Yup.string().min(6),
});



export const schemaValidateCode = Yup.object().shape({
  code: Yup.string().required(fieldRequired),
});

export const schemanewTask = Yup.object().shape({
  title: Yup.string().required(fieldRequired),
  description: Yup.string().required(fieldRequired),
});

export const schemanewAdvice = Yup.object().shape({
  name: Yup.string().required(fieldRequired),
  date_start: Yup.date().required(fieldRequired),
  date_end: Yup.date().required(fieldRequired),
});

export const mapSchema = (model: any) => {
  interface Key {
    [name: string]: any;
  }

  const obj: Key = {};
  type ObjectKey = keyof typeof model.properties;
  Object.keys(model.properties).forEach((key) => {
    const modelKey = key as ObjectKey;
    const modelValue = model.properties[modelKey];
    let validation: Yup.AnyObject = {};

    let val;
    if (modelValue.hide !== true) {
      validation = Yup.string();
      if (
        ["str", "string", "enum", "email", "password", "textarea"].includes(
          modelValue.type
        )
      ) {
        validation = Yup.string();
      }
      if (["bool"].includes(modelValue.type)) {
        validation = Yup.bool();
      }
      if (["date", "datetime"].includes(modelValue.type)) {
        validation = Yup.date();
      }

      if (["int", "integer"].includes(modelValue.type)) {
        validation = Yup.number().typeError(fieldNumber);
      }
      if (["float"].includes(modelValue.type)) {
        validation = Yup.string()
          .typeError(fieldNumber)
          .test("maxDigitsAfterDecimal", fieldDecimal, (number) => {
            if (number) {
              return /^\d*\.{1}\d*$/.test(String(number) || "");
            }
            return true;
          });
      }

      val = validation;
      if (modelValue.required) {
        val = val.required(fieldRequired);
      }

      if (["password"].includes(modelValue.type)) {
        const validPassword = /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
        val = val.matches(validPassword, fieldPassword);
      }

      if (["email"].includes(modelValue.type)) {
        val = val.email(fieldEmail);
      }

      if (modelValue.maxLength) {
        val = val.max(modelValue.maxLength, fieldMax(modelValue.maxLength));
      }
      if (
        [
          "str",
          "string",
          "enum",
          "email",
          "password",
          "int",
          "integer",
          "date",
          "datetime",
          "float",
          "bool",
          "textarea",
        ].includes(modelValue.type)
      ) {
        obj[key] = val;
        validation = {};
      }
    }
  });
  return Yup.object().shape(obj);
};

const getType = (modelValue: any) => {
  if (modelValue.enums) {
    return "select";
  }

  const findType = types.find((item) => item.type === modelValue.type)?.value;

  return findType;
};

export const mapModel = (model: any) => {
  type ObjectKey = keyof typeof model.properties;

  const fieldsMap = Object.keys(model.properties).map((key) => {
    const modelKey = key as ObjectKey;
    const modelValue = model.properties[modelKey];

    const foreign_models = model.foreign_models.find(
      (item: any) => item.name === key
    );

    return {
      name: key,
      type: foreign_models?.model ? "autocomplete" : getType(modelValue),
      label: modelValue.label || modelValue.title || key,
      hide: modelValue.hide,
      enum: modelValue.enums || [],
      default: modelValue.default || "",
      is_float: modelValue.type === "float",
      model: foreign_models?.model,
      foreign_key: foreign_models?.foreign_key,
      primary_key: foreign_models?.primary_key,
      depends_on: foreign_models?.depends_on,
    };
  });

  const cleanData = fieldsMap.map((obj) =>
    Object.fromEntries(
      Object.entries(obj).filter(([_, value]) =>
        Array.isArray(value) ? value.length : value !== undefined
      )
    )
  );

  const mapResponse = cleanData.map((item) => {
    const itemDepend = cleanData.find((a) => item.model === a.depends_on);
    if (itemDepend) {
      return {
        ...item,
        field_search: itemDepend.foreign_key,
      };
    }
    return item;
  });

  return mapResponse;
};
