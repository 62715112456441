import {remove} from "@/services/api";
import { IUserLocation } from "@/interfaces/user_location/IUserLocation";
import { useRegister } from "@/composables/useRegister";
import { MODEL } from "@/utils/constants";
import { handleSearchReadModel } from "@/services/generic";
import { IUser } from "@/interfaces/login/ILoginResponse";

export const handlerServiceDeleteUser = async () => {
    return await remove({
        url: 'user',
    });
};


export const handleUsersByIdsData = async (ids: any): Promise<IUser[]> => {
    try {
        const payload = {
            model: MODEL.USER,
            fields: ["id", "name", "last_name", "image_id"],
        };
        const filter = [
            {
                field: "id",
                op: "in",
                value: ids,
            },
        ];
        const data = await handleSearchReadModel(payload, filter);
        return data.items as IUser[];
    } catch (err) {
        console.error(err);
        return [];
    }
};
