import { defineStore } from "pinia";

export interface TourState {
  team: ITourState;
  teamBody: ITourState;
  teamContextual: ITourState;
  member: ITourState;
  memberBody: ITourState;
  memberContextual: ITourState;
  memberOptions: ITourState;
  talkie: ITourState;
  talkieBody: ITourState;
  talkieContextual: ITourState;
  talkieChat: ITourState;
  task: ITourState;
  taskBody: ITourState;
  taskContextual: ITourState;
  onBoarding: ITourState;
  onBoardingBody: ITourState;
  onBoardingContextual: ITourState;
}

export interface ITourState {
  show: boolean;
  step: number;
}

export interface TourActions {
  setTeamTour: (this: any, payload: ITourState) => void;
  setTeamBodyTour: (this: any, payload: ITourState) => void;
  setTeamContextualTour: (this: any, payload: ITourState) => void;
  setMemberTour: (this: any, payload: ITourState) => void;
  setMemberBodyTour: (this: any, payload: ITourState) => void;
  setMemberContextualTour: (this: any, payload: ITourState) => void;
  setMemberOptionsTour: (this: any, payload: ITourState) => void;
  setTalkieTour: (this: any, payload: ITourState) => void;
  setTalkieBodyTour: (this: any, payload: ITourState) => void;
  setTalkieChatTour: (this: any, payload: ITourState) => void;
  setTalkieContextualTour: (this: any, payload: ITourState) => void;
  setTaskTour: (this: any, payload: ITourState) => void;
  setTaskBodyTour: (this: any, payload: ITourState) => void;
  setTaskContextualTour: (this: any, payload: ITourState) => void;
  setOnBoardingTour: (this: any, payload: ITourState) => void;
  setOnBoardingBodyTour: (this: any, payload: ITourState) => void;
  setOnBoardingContextualTour: (this: any, payload: ITourState) => void;
}

export const useTourStore = defineStore({
  id: "tour",
  state: (): TourState => ({
    team: { show: false, step: 0 },
    teamBody: { show: false, step: 0 },
    teamContextual: { show: false, step: 0 },
    member: { show: false, step: 0 },
    memberBody: { show: false, step: 0 },
    memberContextual: { show: false, step: 0 },
    memberOptions: { show: false, step: 0 },
    talkie: { show: false, step: 0 },
    talkieBody: { show: false, step: 0 },
    talkieContextual: { show: false, step: 0 },
    talkieChat: { show: false, step: 0 },
    task: { show: false, step: 0 },
    taskBody: { show: false, step: 0 },
    taskContextual: { show: false, step: 0 },
    onBoarding: { show: false, step: 0 },
    onBoardingBody: { show: false, step: 0 },
    onBoardingContextual: { show: false, step: 0 },
  }),
  actions: {
    setTeamTour(this: any, payload: ITourState) {
      this.team = payload;
    },
    setTeamBodyTour(this: any, payload: ITourState) {
      this.teamBody = payload;
    },
    setTeamContextualTour(this: any, payload: ITourState) {
      this.teamContextual = payload;
    },
    setMemberTour(this: any, payload: ITourState) {
      this.member = payload;
    },
    setMemberBodyTour(this: any, payload: ITourState) {
      this.memberBody = payload;
    },
    setMemberContextualTour(this: any, payload: ITourState) {
      this.memberContextual = payload;
    },
    setMemberOptionsTour(this: any, payload: ITourState) {
      this.memberOptions = payload;
    },
    setTalkieTour(this: any, payload: ITourState) {
      this.talkie = payload;
    },
    setTalkieBodyTour(this: any, payload: ITourState) {
      this.talkieBody = payload;
    },
    setTalkieContextualTour(this: any, payload: ITourState) {
      this.talkieContextual = payload;
    },
    setTalkieChatTour(this: any, payload: ITourState) {
      this.talkieChat = payload;
    },
    setTaskTour(this: any, payload: ITourState) {
      this.task = payload;
    },
    setTaskBodyTour(this: any, payload: ITourState) {
      this.taskBody = payload;
    },
    setTaskContextualTour(this: any, payload: ITourState) {
      this.taskContextual = payload;
    },
    setOnBoardingTour(this: any, payload: ITourState) {
      this.onBoarding = payload;
    },
    setOnBoardingBodyTour(this: any, payload: ITourState) {
      this.onBoardingBody = payload;
    },
    setOnBoardingContextualTour(this: any, payload: ITourState) {
      this.onBoardingContextual = payload;
    },
  } as TourActions,
});

export type TourStore = ReturnType<typeof useTourStore>;
