import { storeToRefs } from "pinia";
import { CoreStore, useCoreStore } from "@/stores/core";

export default function useCore() {
  const coreStore: CoreStore = useCoreStore();
  const {
    notificationMode,
    notificationReceiptId,
    listeningAudio,
    pauseAudio,
    listeningAudioId,
    searchUserInput,
    refreshLocation,
    refreshUserLocation,
    refreshTask,
    refreshPhoto,
    refreshPlan,
    refreshDashboard,
    refreshDashboardOwner,
    refreshRequest,
    resetPinDigit,
    resetForm,
    refreshNotification,
    showTask,
    taskId,
    selectedUser,
    showDropdown,
    notMoreLocations,
    updateLocationPlan,
    updateUserPlan,
    tabGroupSelected,
    tabJoinRequestSelected,
    timeWithoutAudio,
    teamMembers,
    subscriptionAvailability,
  } = storeToRefs(coreStore);

  return {
    notificationMode,
    notificationReceiptId,
    pauseAudio,
    listeningAudio,
    listeningAudioId,
    searchUserInput,
    refreshLocation,
    refreshUserLocation,
    refreshTask,
    refreshPhoto,
    refreshPlan,
    refreshDashboard,
    refreshDashboardOwner,
    refreshRequest,
    resetPinDigit,
    resetForm,
    refreshNotification,
    showTask,
    taskId,
    selectedUser,
    showDropdown,
    notMoreLocations,
    updateLocationPlan,
    updateUserPlan,
    tabGroupSelected,
    tabJoinRequestSelected,
    timeWithoutAudio,
    teamMembers,
    subscriptionAvailability,
    audioSelected: coreStore.audioSelected,
    pauseAudioSelected: coreStore.pauseAudio,
    setRefreshPhoto: coreStore.setRefreshPhoto,
    setRefreshTask: coreStore.setRefreshTask,
    setRefreshPlan: coreStore.setRefreshPlan,
    setRefreshDashboard: coreStore.setRefreshDashboard,
    setRefreshDashboardOwner: coreStore.setRefreshDashboardOwner,
    setRefreshRequest: coreStore.setRefreshRequest,
    setPhoneSaved: coreStore.setPhoneSaved,
    toggleTask: coreStore.toggleTask,
    setSelectedUser: coreStore.setSelectedUser,
    setShowDropdown: coreStore.setShowDropdown,
    setNotMoreLocation: coreStore.setNotMoreLocation,
    setUpdateLocationPlan: coreStore.setUpdateLocationPlan,
    setUpdateUserPlan: coreStore.setUpdateUserPlan,
    setGroupTabSelected: coreStore.setGroupTabSelected,
    setJoinRequestTabSelected: coreStore.setJoinRequestTabSelected,
    setResetPinDigit: coreStore.setResetPinDigit,
    setResetForm: coreStore.setResetForm,
    setRefreshNotification: coreStore.setRefreshNotification,
    setTimeWithoutAudio: coreStore.setTimeWithoutAudio,
    resetTimeWithoutAudio: coreStore.resetTimeWithoutAudio,
    setTeamMembers: coreStore.setTeamMembers,
    setSubscriptionAvailability: coreStore.setSubscriptionAvailability,
  };
}
