import { reactive, toRefs } from "vue";
import { MODEL, PLAN_TYPE_CODE } from "@/utils/constants";
import { IPlan } from "@/interfaces/plan/IPlan";
import { handleSearchReadModel } from "@/services/generic";

export function usePlan() {
  const state = reactive({
    plans: <IPlan[]>[],
    planUsers: <IPlan[]>[],
    plansFiltered: <IPlan[]>[],
  });

  /**
   * Get all plans
   */
  const getSubscriptionAllPlans = async () => {
    state.plans = [];
    try {
      const payload = {
        model: MODEL.SUBSCRIPTION_PLAN,
        fields: [
          "id",
          "name",
          "location_max",
          "user_max",
          "price_per_user",
          "audio_life_time",
          "task_max",
          "price",
          "store_id",
          "is_product",
        ],
      };

      handleSearchReadModel(payload).then((data) => {
        state.plans = data.items as IPlan[];
      });
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Get all plans different to users
   */
  const getSubscriptionPlans = async () => {
    state.plans = [];
    try {
      const payload = {
        model: MODEL.SUBSCRIPTION_PLAN,
        fields: [
          "id",
          "name",
          "location_max",
          "user_max",
          "price_per_user",
          "audio_life_time",
          "task_max",
          "price",
          "store_id",
        ],
      };

      handleSearchReadModel(payload, [
        { field: "is_product", op: "!=", value: true },
      ]).then((data) => {
        state.plans = data.items as IPlan[];
      });
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Get only plan PRO
   */
  const getSubscriptionPlansPro = async () => {
    try {
      const payload = {
        model: MODEL.SUBSCRIPTION_PLAN,
        fields: [
          "id",
          "name",
          "location_max",
          "user_max",
          "audio_life_time",
          "task_max",
          "price",
          "store_id",
        ],
      };

      handleSearchReadModel(payload, [
        { field: "is_product", op: "!=", value: true },
        { field: "id", op: "==", value: PLAN_TYPE_CODE.PRO },
      ]).then((data) => {
        state.plans = data.items as IPlan[];
      });
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Get only plan for users
   */
  const getSubscriptionPlansUser = async () => {
    try {
      const payload = {
        model: MODEL.SUBSCRIPTION_PLAN,
        fields: [
          "id",
          "name",
          "location_max",
          "user_max",
          "audio_life_time",
          "task_max",
          "price",
          "store_id",
        ],
      };

      handleSearchReadModel(payload, [
        { field: "is_product", op: "!=", value: false },
      ]).then((data) => {
        state.planUsers = data.items as IPlan[];
      });
    } catch (err) {
      console.error(err);
    }
  };

  return {
    ...toRefs(state),
    getSubscriptionPlans,
    getSubscriptionPlansPro,
    getSubscriptionPlansUser,
    getSubscriptionAllPlans,
  };
}
