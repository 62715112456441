import { defineStore } from "pinia";
import {
  NOTIFICATION_TYPE,
  PLAN_TYPE,
  USER_TYPES,
  GROUP_OWNER_VIEW,
  APPLICATION_STATUS,
} from "@/utils/constants";
import { IUser } from "@/interfaces/login/ILoginResponse";
import { ILocation } from "@/interfaces/locale/ILocation";
import { ITeamMember } from "@/interfaces/ITeamMember";
import { ISubscriptionAvailability } from "@/interfaces/ISubscriptionAvailability";

export interface GlobalState {
  loading: boolean;
  helpStep: number;
  userType: string;
  planSelected: number;
  pin?: string;
  phone?: string;
  prefixNumber?: string;
  logout: boolean;
  notificationMode: string;
  notificationReceiptId: number;
  pauseAudio: boolean;
  listeningAudio: boolean;
  listeningAudioId: number;
  searchUserInput: string;
  refreshLocation: boolean;
  refreshUserLocation: boolean;
  refreshTask: boolean;
  refreshPhoto: boolean;
  refreshPlan: boolean;
  refreshDashboardOwner: boolean;
  refreshDashboard: boolean;
  refreshRequest: boolean;
  resetPinDigit: boolean;
  resetForm: boolean;
  refreshNotification: boolean;
  showTask: boolean;
  taskId: number;
  tokenId: string | null;
  phoneSaved: string | null;
  selectedUser: IUser | null;
  selectedLocale: ILocation | null;
  showDropdown: boolean;
  newLocal: boolean;
  notMoreLocations: boolean;
  updateLocationPlan: boolean;
  updateUserPlan: boolean;
  tabGroupSelected: string;
  tabJoinRequestSelected: string;
  timeWithoutAudio: number;
  teamMembers: ITeamMember | [];
  subscriptionAvailability: ISubscriptionAvailability | null;
}

export interface NotificationState {
  mode: string;
  id: number;
}

export interface AudioState {
  mode: boolean;
  id: number;
}

export interface NotificationInput {
  status: boolean;
  id: number;
}

export interface GlobalActions {
  nextHelpStep: (this: any, step: number) => void;
  previousHelpStep: (this: any, step: number) => void;
  resetHelpStep: (this: any) => void;
  setUserType: (this: any, type: string) => void;
  setPlanType: (this: any, type: number) => void;
  setPin: (this: any, pin: string) => void;
  setPhone: (this: any, phone: string, prefix: string) => void;
  setLogout: (this: any, logout: boolean) => void;
  toggleLoading: (this: any, loading: boolean) => void;
  setNotificationMode: (this: any, payload: NotificationState) => void;
  audioSelected: (this: any, payload: AudioState) => void;
  audioPause: (this: any, payload: boolean) => void;
  searchUser: (this: any, payload: string) => void;
  setRefreshLocation: (this: any, payload: boolean) => void;
  setRefreshUserLocation: (this: any, payload: boolean) => void;
  setRefreshTask: (this: any, payload: boolean) => void;
  setRefreshPhoto: (this: any, payload: boolean) => void;
  setRefreshPlan: (this: any, payload: boolean) => void;
  setRefreshDashboard: (this: any, payload: boolean) => void;
  setRefreshDashboardOwner: (this: any, payload: boolean) => void;
  setRefreshRequest: (this: any, payload: boolean) => void;
  setTokenId: (this: any, payload: string | null) => void;
  setPhoneSaved: (this: any, payload: string | null) => void;
  toggleTask: (this: any, payload: NotificationInput) => void;
  setSelectedUser: (this: any, payload: IUser | null) => void;
  setSelectedLocale: (this: any, payload: ILocation | null) => void;
  setShowDropdown: (this: any, payload: boolean) => void;
  setNewLocal: (this: any, payload: boolean) => void;
  setNotMoreLocation: (this: any, payload: boolean) => void;
  setUpdateLocationPlan: (this: any, payload: boolean) => void;
  setUpdateUserPlan: (this: any, payload: boolean) => void;
  setGroupTabSelected: (this: any, payload: string) => void;
  setJoinRequestTabSelected: (this: any, payload: string) => void;
  setResetPinDigit: (this: any, payload: boolean) => void;
  setResetForm: (this: any, payload: boolean) => void;
  setRefreshNotification: (this: any, payload: boolean) => void;
  setTimeWithoutAudio: (this: any) => void;
  resetTimeWithoutAudio: (this: any) => void;
  setTeamMembers: (this: any, payload: ITeamMember) => void;
  setSubscriptionAvailability: (
    this: any,
    payload: ISubscriptionAvailability
  ) => void;
}

export const useCoreStore = defineStore({
  id: "core",
  state: (): GlobalState => ({
    loading: false,
    helpStep: 1,
    userType: USER_TYPES.EMPLOYEE,
    planSelected: PLAN_TYPE.PRO,
    pin: "",
    phone: "",
    logout: false,
    notificationMode: NOTIFICATION_TYPE.LOCATION,
    notificationReceiptId: -1,
    listeningAudio: false,
    pauseAudio: false,
    listeningAudioId: 0,
    searchUserInput: "",
    refreshLocation: false,
    refreshUserLocation: false,
    refreshTask: false,
    refreshPhoto: false,
    refreshPlan: false,
    refreshDashboardOwner: false,
    refreshDashboard: false,
    refreshRequest: false,
    resetPinDigit: false,
    resetForm: false,
    refreshNotification: false,
    tokenId: null,
    phoneSaved: null,
    showTask: false,
    taskId: -1,
    selectedUser: null,
    selectedLocale: null,
    showDropdown: false,
    newLocal: false,
    notMoreLocations: false,
    updateLocationPlan: false,
    updateUserPlan: false,
    tabGroupSelected: GROUP_OWNER_VIEW.REQUESTS,
    tabJoinRequestSelected: APPLICATION_STATUS.WAITING,
    timeWithoutAudio: 0,
    teamMembers: [],
    subscriptionAvailability: null,
  }),
  actions: {
    nextHelpStep(this: any, step: number) {
      this.helpStep = step;
    },
    previousHelpStep(this: any, step: number) {
      this.helpStep = step;
    },
    resetHelpStep(this: any) {
      this.helpStep = 1;
    },
    setUserType(this: any, type: string) {
      this.userType = type;
    },
    setPlanType(this: any, type: number) {
      this.planSelected = type;
    },
    setPin(this: any, pin: string) {
      this.pin = pin;
    },
    setPhone(this: any, phone: string, prefix: string) {
      this.phone = phone;
      this.prefixNumber = prefix;
    },
    setLogout(this: any, logout: boolean) {
      this.logout = logout;
    },
    toggleLoading(this: any, loading: boolean) {
      this.loading = loading;
    },
    setNotificationMode(this: any, payload: NotificationState) {
      this.notificationMode = payload.mode;
      this.notificationReceiptId = payload.id;
    },
    audioSelected(this: any, payload: AudioState) {
      this.listeningAudio = payload.mode;
      this.listeningAudioId = payload.id;
    },
    /**
     * Pause Audio
     */
    audioPause(this: any, payload: boolean) {
      this.pauseAudio = payload;
    },
    searchUser(this: any, payload: string) {
      this.searchUserInput = payload;
    },
    setRefreshLocation(this: any, payload: boolean) {
      this.refreshLocation = payload;
    },
    setRefreshUserLocation(this: any, payload: boolean) {
      this.refreshUserLocation = payload;
    },
    setRefreshTask(this: any, payload: boolean) {
      this.refreshTask = payload;
    },
    setRefreshPhoto(this: any, payload: boolean) {
      this.refreshPhoto = payload;
    },
    setRefreshPlan(this: any, payload: boolean) {
      this.refreshPlan = payload;
    },
    setRefreshDashboard(this: any, payload: boolean) {
      this.refreshDashboard = payload;
    },
    setRefreshDashboardOwner(this: any, payload: boolean) {
      this.refreshDashboardOwner = payload;
    },
    setRefreshRequest(this: any, payload: boolean) {
      this.refreshRequest = payload;
    },
    setTokenId(this: any, payload: string | null) {
      this.tokenId = payload;
    },
    setPhoneSaved(this: any, payload: string | null) {
      this.phoneSaved = payload;
      if (typeof payload === "string") {
        localStorage.setItem("phone_number", payload);
      }
    },
    toggleTask(this: any, payload: NotificationInput) {
      this.showTask = payload.status;
      this.taskId = payload.id;
    },
    setSelectedUser(this: any, payload: IUser | null) {
      this.selectedUser = payload;
    },
    setSelectedLocale(this: any, payload: ILocation | null) {
      this.selectedLocale = payload;
    },
    setShowDropdown(this: any, payload: boolean) {
      this.showDropdown = payload;
    },
    setNewLocal(this: any, payload: boolean) {
      this.newLocal = payload;
    },
    setNotMoreLocation(this: any, payload: boolean) {
      this.notMoreLocations = payload;
    },
    setUpdateLocationPlan(this: any, payload: boolean) {
      this.updateLocationPlan = payload;
    },
    setUpdateUserPlan(this: any, payload: boolean) {
      this.updateUserPlan = payload;
    },
    setGroupTabSelected(this: any, payload: string) {
      this.tabGroupSelected = payload;
    },
    setJoinRequestTabSelected(this: any, payload: string) {
      this.tabJoinRequestSelected = payload;
    },
    setResetPinDigit(this: any, payload: boolean) {
      this.resetPinDigit = payload;
    },
    setResetForm(this: any, payload: boolean) {
      this.resetForm = payload;
    },
    setRefreshNotification(this: any, payload: boolean) {
      this.refreshNotification = payload;
    },
    setTimeWithoutAudio(this: any) {
      this.timeWithoutAudio += 1;
    },
    resetTimeWithoutAudio(this: any) {
      this.timeWithoutAudio = 0;
    },
    setTeamMembers(this: any, payload: ITeamMember) {
      this.teamMembers = payload;
    },
    setSubscriptionAvailability(this: any, payload: ISubscriptionAvailability) {
      this.subscriptionAvailability = payload;
    },
  } as GlobalActions,
});

export type CoreStore = ReturnType<typeof useCoreStore>;
