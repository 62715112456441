export default {
  components: "Componentes",
  global: {
    help: "Ayuda",
    contact: "Contacto",
    goBack: "Volver",
    month: "mes",
    phone: "Teléfono",
    home: "Inicio",
    locals: "Equipos",
    talkie: "Talkie",
    tasks: "Tareas",
    team: "Equipo",
    codeCopied: "Código copiado con éxito",
    shareCodeTitle: "¡Hola!",
    shareCodeDescription:
      "{name} te ha invitado a unirte a su equipo en Wehop. Descarga la app ahora, disponible para {platform}. \n" +
      "Completa el registro e ingresa el siguiente código para unirte al equipo: {code} \n" +
      "https://play.google.com/store/apps/details?id=app.wehop.app&hl=es_ES&gl=ES",
    hi: "¡Hola!",
    viewDetail: "Ver detalle",
    leave: "Abandonar",
    updateSuccess: "Solicitud actualizada",
    dataUpdated: "Datos actualizados",
    profileUpdated: "Perfil actualizado",
    rejectSuccess: "Solicitud rechazada",
    changesSaved: "Cambios guardados",
    searchByName: "Buscar por nombre",
    today: "Hoy",
    yesterday: "Ayer",
    prevDate: "Antiguos",
    logout: "Cerrar sesión",
    profile: "Perfil",
    users: "Usuarios",
    members: "Miembros",
    owner: "Propietario",
    member: "Miembro",
    manager: "Administrador",
    invitations: "Invitaciones",
    yourTeams: "Tus equipos",
    unReadMessages: "Audios",
    yourInvitations: "Solicitudes",
    pendingTask: "Tareas",
    notMoreLocations: "No hay equipos disponibles",
    notMoreLocationsDescription: "Para agregar más equipos debes cambiar al plan PRO",
    addLocations: "Agregar",
    deleteUser: "Dar de baja mi cuenta",
    deleteUserHeader: "¿Estás seguro que quieres dar de baja tu cuenta en Wehop?",
    deleteUserBody: 
      "Recuerda que una vez eliminada la cuenta no \npodrás recuperar ninguna información.",
    notMoreUsers: "Has llegado al límite máximo de usuarios en tu equipo",
    notMoreUsersDescription: "Para poder agregar más usuarios a tu equipo escoge una de estas dos opciones",
    addUsers: "Comprar usuario",
    changePlan: "Cambiar de plan",
    atention: "¡Atención!",
    createTeamMessage: "Para disfrutar de Wehop lo primero que debes hacer es crear tu primer equipo.",
    ready: "¡Listo!",
  },
  actions: {
    cancel: "Cancelar",
    save: "Guardar",
    continue: "Continuar",
    skip: "Omitir",
    goTo: "Entrar",
    goToHome: "Ir al inicio",
    goToDashboard: "Ir al dashboard",
    select: "Seleccionar",
    logIn: "Ingresar",
    changePhoto: "Cambiar foto",
    copyCode: "Copiar código",
    shareCode: "Compartir código de negocio",
    share: "Compartir",
    joinTeam: "Unirme a un equipo",
    validateCode: "Validar código",
    sendRequest: "Enviar solicitud",
    joinAnotherTeam: "Unirse a otro equipo",
    viewMyRequest: "Ver mi solicitud",
    update: "Guardar cambios",
    confirm: "Confirmar",
    reject: "Rechazar",
    improvePlan: "Mejorar mi plan",
    downgradePlan: "Bajar plan",
    accept: "Aceptar",
    no: "No",
    confirmDelete: "Sí, eliminar",
    buyPlan: "Comprar",
    finish: "Finalizar",
  },
  forgotPassword: {
    title: "¿Has olvidado tu contraseña?",
    description:
      "Tranquilo podrás restablecer tu contraseña en tres sencillos pasos.",
    phone: "Escribe tu teléfono",
    changePassword: "Cambiar tu contraseña",
    changePasswordDescription:
      "Ya casi lo tienes, escribe tu nueva contraseña ¡y listo!",
    newPassword: "Nueva contraseña",
    repeatPassword: "Repite la contraseña",
    congratulations: "¡Enhorabuena!",
    congratulationsDescription:
      "Tu contraseña ha sido modificada con éxito, ya puedes entrar de nuevo y disfrutar de WeHop.",
    goToLogin: "Volver a iniciar sesión",
  },
  rules: {
    required: "El campo es requerido",
    email: "Introduce un correo electrónico válido",
    password:
      "Tu contraseña debe contener mínimo 8 caracteres, una mayúscula y un carácter especial",
    number: "Por favor ingrese un número valido",
    decimal:
      "El campo numérico debe tener 2 dígitos después del decimal o menos",
    max: "El campo es no debe contener más de {max} caracteres",
    pinCode: "Introduce el código de verificación",
    pinCodeCompleted: "Pin code completado",
    pinCodeNotCompleted: "Pin code incompleto",
    passwordMismatch: "Las contraseñas no coinciden",
  },
  welcome: {
    title: "Bienvenido",
    subtitle: "Conecta, accede y comparte con tu equipo desde cualquier lugar.",
    register: "Registrarme",
    login: "Iniciar sesión",
  },
  login: {
    phone: "Escribe tu teléfono",
    forgotPassword: "¿Olvidaste tu contraseña?",
    iDontHave: "Áun no tengo una cuenta",
    register: "Registrarme",
    password: "Escribe tu contraseña",
  },
  register: {
    continue: "Continuar",
    createProfile: "Crear mi perfil",
    createTeam: "Crear Equipo",
    addPhotoToProfile: "Agregar tu foto",
    addPhotoToProfileDescription:
      "Ayuda a tus compañeros de equipo a reconocerte en WeHop",
    profileCreated: "Perfil creado",
    profileCreatedDescription:
      "¡Enhorabuena ya formas parte de WeHop! Ahora podrás estar en contacto con todo tu equipo. ",
    selectPlan: "Selecciona tu plan",
    planInclude: "El plan incluye:",
    planLocalUnlimited: "Equipos ilimitados.",
    planLocals: "0 equipos | Un equipo. | {local} equipos.",
    planUserUnlimited: "Usuarios ilimitados.",
    planUsers: "{user} Usuarios",
    planUsersTitle: "Nuevos usuarios",
    planMessageUnlimited: "Mensajes guardados ilimitadamente.",
    planIncludeNewUsers: "Selecciona cuántos cupos de usuarios quieres añadir a tu equipo",
    planMessages: "Mensajes guardados durante {message}.",
    planTaskBoardUnlimited: "Tablón con tareas ilimitadas.",
    planTaskBoard: "Tablón con 1 tarea. | Tablón con {taskBoard} tareas",
    planCostPerUser: "mes por usuario adicional",
    signUp: "Darse de alta",
    businessInfo: "Datos del negocio",
    businessInfoDescription:
      "Estamos creando tu negocio y por ello nos gustaría saber:",
    businessInfoDescriptionFinalStep:
      "¡Ya casi esta! Solo nos queda que nos dejes esta información sobre tu negocio:",
    theMostOutstanding: "El más destacado",
    legalNotice: "Aviso legal",
    privacyPolicy: "Política de privacidad",
    iAccept: "Acepto",
    thePrivacyPolicy: "la política de privacidad",
    and: "y",
    conditions: "Términos y condiciones",
    businessName: "Nombre del comercio",
    companyName: "Razón social",
    address: "Dirección",
    city: "Ciudad",
    country: "País",
    email: "Email",
    corporateEmail: "Correo corporativo",
    phone: "Teléfono",
    phonePrefix: "Prefijo",
    locals: "N° de equipos",
    employees: "N° de miembros",
    uploadPhoto: "Editar photo",
    whatDoYouWantToDo: "¿Qué quieres hacer?",
    fullName: "Nombre",
    firstName: "Nombre",
    lastName: "Apellido",
    newPassword: "Nueva contraseña",
    repeatPassword: "Repite la contraseña",
    birthday: "Fecha de nacimiento",
    verificationCode: "Código de verificación",
    verificationCodeDescription:
      "Te hemos mandado un SMS con un código de verificación al número de teléfono que nos has dado, por favor introduce el código:",
    changePassword: "Cambia tu contraseña",
    changePasswordMessage:
      "Ya casi lo tienes, escribe tu nueva contraseña ¡y listo!",
    term: {
      objective: "Objetivo",
      aboutUs: "Sobre nosotros",
      whatIsWeHop: "¿Qué es WeHop?",
      conditions: "Condiciones de uso de nuestra App y página web",
      contract: "Condiciones de contratación de nuestros servicios",
      features: "Funcionamiento de WeHop",
      employee: "Condiciones de uso del usuario con sus miembros",
      price: "Precio, pago y método de pago",
      responsibility: "Responsabilidad",
      resolution: "Resolución",
      dataProtection: "Protección de datos",
      industrialProtection: "Protección intelectual e industrial",
      modifyConditions: "Modificaciones de condiciones",
      law: "Ley aplicable  y jurisdicción",
    },
    policy: {
      privacyPolicy: "Política de privacidad",
      privacyPolicyMessage:
        "Acepto la política de privacidad y los términos y condiciones",
      responsible: "Responsable del tratamiento",
      data: "Datos recopilados",
      treatment: "Finalidad del tratamiento",
      legitimation: "Legitimación del tratamiento",
      transfers: "Transferencias internacionales",
      userRights: "Derechos de los usuarios",
      changes: "Cambios en la política de privacidad de WEHOP",
      cookies: "Política de Cookies",
      dataRetentionPeriod: "Periodo de retención de datos",
      security: "Medidas de seguridad aplicadas a sus datos",
    },
    convertToOwner: "Convertir en propietario",
    downgradePlan: "Bajar plan",
  },
  group: {
    selectGroup: "Select a group",
    copy: "Copiar código",
    share: "Compartir código",
    members: "Miembros",
    requests: "Solicitudes",
    detail: "Detalle",
    edit: "Editar usuario",
    delete: "Eliminar",
    deleteUserConfirm: "¿Seguro quieres eliminarle?",
    deleteUserConfirmMessage:
      "Al eliminar al usuario perderás su información personal y ya no podrás contactarlo dentro de la app",
    rememberLimitUsers: "Recuerda que solo puedes seleccionar hasta 5 usuarios",
    rememberLimitTeams: "Recuerda que solo puedes seleccionar hasta un equipo"
  },
  joinTeam: {
    joinATeam: "Únete a un equipo",
    youKnowCode: "¿Conoces el código?",
    youKnowCodeDescription:
      "Para unirte a un equipo ingresa a continuación el código que te enviaron previamente y envíales una solicitud. \nEl propietario del equipo deberá aceptar tu solicitud para unirte y disfrutar de las funciones de la aplicación",
    whiteCode: "Escribe el código de equipo",
    youWillJoin: "Te unirás al equipo",
    joinConfirm:
      "Envía tu solicitud para unirte al equipo y espera la confirmación del propietario.",
    joinConfirmSecond:
      "Accederás automáticamente al equipo una vez acepten tu solicitud",
    requestSent: "Solicitud enviada",
    requestSentMessage:
      "Accederás automáticamente al equipo una vez acepten tu solicitud",
    myWorkspaces: "Mis espacios de trabajo",
    actives: "Activos",
    requests: "Solicitudes",
    requestWrong: "¡Ups! Algo salió mal",
    requestWrongMessage:
      "La solicitud de ingreso no se ha completado. Por favor, vuelve a intentarlo más tarde.",
    code: "Código",
    status: "Estado",
    userId: "User Id",
    requestWaitingMessage:
      "Tu solicitud de ingreso al equipo {name} se encuentra en espera de aprobación",
    requestRejectedMessage:
      "Tu solicitud de ingreso al equipo {name} fue rechazada",
    leaveTeam: "¿Estás seguro que quieres abandonar el equipo? ",
    leaveTeamDescription:
      "Al abandonar el equipo no podrás volver a acceder a ninguna sección del mismo, y quedarás desvinculado. ",
    employee: "Miembro",
    manager: "Administrador",
    assignRole: "Asigna un rol al miembro",
    assignRoleDescription:
      "Como dueño del negocio, podrás cambiar el rol del miembro en cualquier momento",
    ownersRole: "Propietarios",
    managersRole: "Administradores",
    employeesRole: "Miembros",
    sector: "Sector Empresarial",
  },
  myProfile: {
    profile: "Perfil",
    myData: "Mis datos",
    myProfile: "Mi perfil",
    myBusiness: "Mi negocio",
    myTeams: "Mis Equipos",
    setting: "Configuración",
    logout: "Cerrar sesión",
    myCurrentPlan: "Mi plan actual",
    updateData: "Editar datos",
    commercialName: "Nombre comercial",
    name: "Razón social",
    address: "Dirección",
    city: "Ciudad",
    country: "País",
    vat: "CIF/NIF",
    phone: "Teléfono",
    email: "Email corporativo",
  },
  locale: {
    localeDescriptionAdd:
      "Podrás cambiar el nombre más adelante. La dirección se usará para ayudarte a diferenciar negocios con un mismo nombre",
    localeCreated: "Nuevo equipo creado",
    adminLocales: "Administrar equipos",
    localeManager: "Gestor de equipos",
    editLocale: "Editar equipo",
    newLocale: "Nuevo equipo",
    createLocale: "Crear equipo",
  },
  talkie: {
    generalChannel: "Canal general del equipo",
    detail: "Detalle",
    showButton: "Mostrar botón flotante",
    markAsRead: "Marcar como leído",
    talkingTo: "Hablando a",
    isTalkingToYou: "te está hablando",
    yourTalks: "Tus conversaciones",
    searchByName: "Buscar por nombre",
    markAsListened: "Marcar como escuchados",
  },
  tasks: {
    pending: "Pendientes",
    finished: "Finalizadas",
    list: "Lista de tareas",
    filter: "Filtrar tareas",
    priority: "Prioridad",
    priorities: "Prioritaria",
    no_priorities: "No prioritaria",
    all: "Todas",
    assigned_tasks: "Tareas asignadas",
    no_assigned: "No asignadas",
    assigned: "Asignadas",
    assigned_to: "Asignado a",
    edit: "Editar tarea",
    delete: "Eliminar tarea",
    created_by: "Creada por",
    finish: "Finalizar tarea",
    fail_finish: "Ocurrió un error al finalizar la tarea",
    success_finished: "La tarea ha sido finalizada con éxito",
    fail_remove: "Ocurrió un error al eliminar la tarea",
    success_removed: "La tarea se ha eliminado correctamente",
    finished_task: "Terminada",
    updated_task: "La tarea se ha actualizado correctamente",
    remove_description:
      "Perderás toda la información registrada y no podrás volver a editar estos elementos",
    remove_title: "Vas a eliminar la tarea del listado",
    finalized_by: "Finalizada por",
    addTask: "Crear Tarea",
    priorityTask: "Tarea prioritaria",
  },
  payment: {
    success_title: "Pago aceptado",
    success_description:
      "¡Enhorabuena! Ya puedes empezar a dar de alta todos tus equipos de forma que puedas estar conectado con todo tu equipo.",
    unverified_title: "¡Ups! Algo salió mal",
    unverified_description:
      " No hemos podido verificar tus datos. Por favor, vuelve a intentarlo de nuevo.",
    failed_title: "Pago rechazado",
    failed_description:
      "Lo sentimos no hemos podido acceder a los datos aportados. Por favor vuelva a intentarlo más tarde.",
    action_goto_dashboard: "Ir a mi dashboard",
    action_retry: "Volver a intentarlo",
    action_create_local: "Crear nuevo equipo",
    add_new_user: "Agregar nuevo usuario",
    retry_verification:
      "Error al validar los datos. Por favor, intenta nuevamente.",
    action_downgrade: "Cambiar de plan",
    planChangeTitle: "Se ha cambiado exitosamente tu plan",
    planChangeUsersTitle: "¡Hasta 5 usuarios!",
    planChangeTeamTitleDescriptionOption: "Recuerda que solo podrás tener este equipo</br><br>Debes seleccionar de tu listado de equipos con cuál equipo te vas a quedar:",
    planChangeTitleDescriptionOption1: "Ya puedes continuar usando las funcionalidades de tu equipo." +
      "<br><br>Recuerda que solo podrás tener este equipo y podrás mantener hasta 5 usuarios o añadir nuevos usuarios por un coste adicional",
    planChangeTitleDescriptionOption2: "Recuerda que solo podrás tener este equipo.<br><br>Tienes un cupo de hasta 5 usuarios que se unan a tu equipo.<br><br>Actualmente, tienes {users} usuarios en tu equipo, debes elegir si:</br><br>",
    planChangeTitleDescriptionOption3: "Actualmente, tienes {users} usuarios en tu equipo</br><br>Deberás seleccionar hasta 5 usuarios para continuar con el funcionamiento de tu equipo:",
    addUsers: "Añadir usuarios",
    selectUsers: "Seleccionar usuarios",
    downgradeUserFinished: "Ya puedes continuar usando las funcionalidades de tu equipo.",
    downgradeTeamFinished: "Ya puedes continuar usando las funcionalidades de tu equipo.</br><br>Se mantendrán todos los usuarios que tienes en {locale_name}.</br><br>Recuerda que siempre puedes comprar más de 5 usuarios.",
  },
  advice: {
    addAdvice: "Crear aviso",
    edit: "Editar aviso",
    delete: "Eliminar aviso",
    success_removed: "El aviso se ha eliminado correctamente",
    fail_remove: "Ocurrió un error al eliminar el aviso",
    updated_advice: "El aviso se ha actualizado correctamente",
  },
  navigation: {
    home: "Inicio",
    talkie: "Talkie",
    tasks: "Tareas",
  },
  notification: {
    talkieMessage: "Tienes un nuevo audio",
    newTaskAssignedMessage: "Nueva tarea asignada",
  },
  tour: {
    tour1_1: {
      title: "Datos personales del propietario",
      text:
        "¡Vamos a conocernos!\n" +
        "Lo primero que debes hacer es crear una cuenta, para ello completa tus datos personales a continuación, valida tu identidad y selecciona el plan que mejor se adapte a tus preferencias",
    },
    tour1_2: {
      title: "Crea tu primer Equipo",
      text:
          "¡Que bien!\n" +
          "Ya tu cuenta se ha creado satisfactoriamente. El siguiente paso, para poder utilizar todas las funcionalidades que te ofrece Wehop, será crear tu primer equipo, para eso solo tienes que completar el siguiente formulario",
    },
    tour1_3: {
      title: "Datos personales del miembro",
      text: "¡Vamos a conocernos!<br> Lo primero que debes hacer es crear una cuenta, para ello completa tus datos personales a continuación y valida tu identidad.",
    },
    tour1_4: {
      title: "Unirte a un equipo",
      text: "Para unirte a un equipo, debes ingresar el código correspondiente que te ha enviado previamente el propietario de ese equipo y enviarle una solicitud para unirte",
    },
    tour2_1: {
      title: "Crear más equipos",
      text: "Cuando quieras crear más equipos deberás dirigirte sobre el módulo de Equipos en el menú principal.",
    },
    tour2_2: {
      title: "Crear más equipos",
      text: "A través del ícono azul te llevará automáticamente a rellenar el formulario para crear un nuevo local. Recuerda que con el plan Pro podrás crear tantos equipos como tú quieras.",
    },
    tour2_3: {
      title: "Opciones de un equipo",
      text: "Para poder gestionar tus equipos tendrás varias opciones pulsando en este ícono: <ul><li>Podrás editar algún dato de tu equipo</li><li>Podrás eliminar un equipo que hayas creado con anterioridad.</li><li>Además, te permite invitar a otros usuarios a unirse a tus equipos. Deberán unirse a través de un código de invitación.</li></ul> Para acceder a ese código tienes dos maneras:",
    },
    tour2_4: {
      title: "Invitar miembros a tus equipos",
      text: '1. Puedes pulsar sobre <strong>"Copiar código"</strong>,y pegar ese código en otra aplicación.',
    },
    tour2_5: {
      title: "Invitar miembros a tus equipos",
      text: '2. También, puedes enviar el código a otro miembro a través de otra app que tengas en tu móvil, pulsando sobre la opción de <strong>"Compartir código"</strong>',
    },
    tour2_6: {
      title: "Eliminar un equipo",
      text: "Al <strong style='color=red'>eliminar un equipo</strong> recuerda que perderás toda la información y los miembros asociados a ese equipo",
    },
    tour3_1: {
      title: "Tablero de tareas",
      text: "Para poder consultar y gestionar las tareas y avisos creados en tu equipo debes dirigirte al módulo de Tareas",
    },
    tour3_2: {
      title: "Crear tareas o avisos",
      text: "Para crear una nueva tarea o aviso debes pulsar sobre este ícono, dependiendo de la opción que selecciones te llevará al formulario correspondiente",
    },
    tour3_3: {
      title: "Opciones de una tarea",
      text: "Dentro de una tarea tendrás múltiples opciones como: <ul><li>Editarla</li><li>Eliminarla</li><li>Asignarsela a un miembro de tu equipo</li><li>Comentar dentro de la tarea</li><li>Finalizar la tarea.</li></ul>",
    },
    tour4_1: {
      title: "Módulo de Miembros",
      text: "Te contamos un poco sobre los roles de la aplicación: Propietario: es el usuario dueño del equipo <ul><li>Administrador: son aquellos miembros que pueden forman parte de tu equipo pero tienen el privilegio de gestionar información y demás miembros.</li><li>Miembros: son todos los usuarios que se han unido a tu equipo</li></ul>",
    },
    tour4_2: {
      title: "Opciones de un miembro",
      text: "Cada miembro que forme parte de tu equipo tendrá diferentes opciones como: <ul><li>En Editar podrás cambiar el rol que tenga el miembro</li><li>Podrás eliminar un miembro de tu equipo.</li></ul>",
    },
    tour4_3: {
      title: "Eliminar un miembro",
      text: "Si <strong style='color:red'>eliminas un miembro</strong> de tu equipo, ya no podrá acceder a ninguna funcionalidad ni información de tu equipo.",
    },
    tour4_4: {
      title: "Invitar miembros a tus equipos",
      text: "Puedes pulsar sobre Copiar código, y pegar ese código en otra aplicación.",
    },
    tour4_5: {
      title: "Invitar miembros a tus equipos",
      text: "También, puedes enviar el código a otro miembro a través de otra app que tengas en tu móvil, pulsando sobre la opción de Compartir código",
    },
    tour5_1: {
      title: "Talkie",
      text: "En el módulo de Talkie podrás comunicarte con otros miembros a través de audios que formen parte de tu equipo",
    },
    tour5_2: {
      title: "Enviar mensajes de voz grupales",
      text: "Para enviarle audios a todos los miembros que forman parte de tu equipo, sigue estos pasos: <ol><li>Pulsa una vez el botón de audio para que se active la grabación</li><li>Habla con total normalidad</li><li>Vuelve a pulsar sobre el botón de audio para detener la grabación y enviar el audio.</li>",
    },
    tour5_3: {
      title: "Botón de grabación",
      text: "Recuerda que si un miembro de tu equipo está grabando un audio el botón se bloqueará hasta que este miembro termine su grabación",
    },
    tour5_4: {
      title: "Enviar mensajes de voz individuales",
      text: "También podrás comunicarte con un miembro específico. Para eso debes: <ol><li>Pulsa una vez el avatar del miembro que quieres enviarle el audio para que se active la grabación</li><li>Habla con total normalidad</li><li>Vuelve a pulsar sobre el avatar para detener la grabación y enviar el audio.</li></ol>",
    },
    tour5_5: {
      title: "Botón flotante",
      text: "Utilizar el botón flotante te permite enviar audios a todos los miembros de tu equipo y a la vez poder estar navegando en otras apps de tu móvil",
    },
    tour5_6: {
      title: "Botón flotante",
      text: "Debes habilitar en tu móvil el funcionamiento del botón flotante. Una vez habilitado debes: <ol><li>Pulsa una vez el botón de audio para que se active la grabación</li><li>Habla con total normalidad</li><li>Vuelve a pulsar sobre el botón de audio para detener la grabación y enviar el audio.</li></ol>",
    },
    tour5_7: {
      title: "Chats",
      text: "A través de este ícono podrás acceder a los chats, tanto el grupal como individuales",
    },
    tour5_8: {
      title: "Hilo de mensajes",
      text: "En cada chat podrás ver los audios que se han enviado",
    },
  },
};
