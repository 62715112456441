import {defineStore} from "pinia";
import {NOTIFICATION_TYPE} from "@/utils/constants";


export interface RecordState {
    nameUser: string;
    durationAudio: number;
    recording: boolean;
    listening: boolean;
    playing: boolean;
    mode: string;
    userId: number;
    showSnackBar: boolean;
}

export interface InfoRecordState {
    nameUser?: string;
    durationAudio?: number;
    status?: boolean;
    recordMode?: string;
    recordUserId?: number;
}

export interface InfoReceiptState {
    mode: string;
    userId?: number;
}

export interface RecordActions {
    toggleRecord: (this: any, info: InfoRecordState) => void;
    toggleListen: (this: any, info: InfoRecordState) => void;
    togglePlay: (this: any, play: boolean) => void;
    setInfoAudio: (this: any, info: InfoRecordState) => void;
    setRecipientUser: (this: any, info: InfoReceiptState) => void;
}

export const useRecordStore = defineStore({
    id: "audio",
    state: (): RecordState => ({
        nameUser: "",
        durationAudio: 0,
        recording: false,
        listening: false,
        playing: false,
        mode: NOTIFICATION_TYPE.LOCATION,
        userId: 0,
        showSnackBar: false
    }),
    actions: {
        setInfoAudio(this: any, info: InfoRecordState) {

            if (this.recording || this.listening) {
                this.nameUser = this.recording && info.recordMode == NOTIFICATION_TYPE.LOCATION ?  'Todos' : info.nameUser || this.nameUser;
                this.durationAudio = info.durationAudio || this.durationAudio;
                this.showSnackBar =
                    this.recording || info.recordMode == NOTIFICATION_TYPE.LOCATION ||
                    (info.recordMode == NOTIFICATION_TYPE.USER);
            } else {
                this.nameUser = "";
                this.durationAudio = 0;
                this.showSnackBar = false;
            }
        },
        setRecipientUser(this: any, info: InfoReceiptState) {
            if (this.recording || this.listening) {
                this.mode = info.mode || this.mode;
                this.userId = info.userId || this.userId;
            }
        },
        toggleRecord(this: any, info: InfoRecordState) {
            this.recording = !this.recording;
            this.setInfoAudio(info);
        },
        toggleListen(this: any, info: InfoRecordState) {
            this.listening = info.status;
            this.setInfoAudio(info);
        },
        togglePlay(this: any, play: boolean) {
            this.playing = play;
        },
    } as RecordActions,
});

export type RecordStore = ReturnType<typeof useRecordStore>;
