import { IListHeader } from "@/interfaces/GenericModel";
import { handleSearchReadModel } from "@/services/generic";
import { useGenericStore, GenericStore } from "@/stores/generic";
import { storeToRefs } from "pinia";
import { useNativePlatform } from "./useNativePlatform";
import { CoreStore, useCoreStore } from "@/stores/core";

export function useList() {
  const coreStore: CoreStore = useCoreStore();
  const storeList: GenericStore = useGenericStore();
  const { generic } = storeToRefs(storeList);

  const getModelData = async (payload: any) => {
    const {
      headers = [],
      modelName,
      page = 1,
      reset = false,
      showLoading = true,
    } = payload;
    const { isMobile } = useNativePlatform();
    if (showLoading) {
      coreStore.toggleLoading(true);
    }

    if (headers.length) {
      storeList.setHeadersModel(modelName, headers);
    }

    const filters = generic.value[`filters_${modelName}`];
    const sortBy = generic.value[`sortBy_${modelName}`];
    const headersModel = generic.value[`headers_${modelName}`] || headers;
    const fields = headersModel.map((item: IListHeader) => item.name);

    const payloadData = {
      model: modelName,
      fields: fields,
      page: page,
    };
    const {
      items: records,
      total,
      size,
    } = await handleSearchReadModel(payloadData, filters, sortBy);

    let mapRecords: any;
    if (isMobile && !reset) {
      const currentRecords = generic.value[`items_${modelName}`] || [];
      const mixRecords = [...new Set([...currentRecords, ...records])];
      const hash: { [id: string]: boolean } = {};
      const array = mixRecords.filter((o) =>
        hash[o.id] ? false : (hash[o.id] = true)
      );
      mapRecords = array;
    } else {
      storeList.setItemsModel(modelName, []);
      mapRecords = records;
    }
    storeList.setPaginationModel(modelName, { total, size, page });
    storeList.setItemsModel(modelName, mapRecords);

    if (showLoading) {
      coreStore.toggleLoading(false);
    }
  };
  return {
    getModelData,
    generic,
  };
}
