import { UploadFile } from "@/interfaces/files/UploadFiles";
import { handlerGetFile } from "@/services/generic";
import { get } from "@/services/api";
import { MODEL, NOTIFICATION_TYPE } from "@/utils/constants";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import useRecord from "@/composables/useRecord";
import {
  handleRemoveNotification,
  handleSendAudioToMessage, playAudioFake
} from "@/services/talkie";
import { IRecording } from "@/interfaces/IRecording";
import { CoreStore, useCoreStore } from "@/stores/core";
import { RecordStore, useRecordStore } from "@/stores/record";
import { handleMe } from "@/services/me";
import { IUser } from "@/interfaces/login/ILoginResponse";
import { useLocation } from "@/composables/useLocation";
import router from "@/router";

export function useAudio() {
  const registerStore: RegisterStore = useRegisterStore();
  const coreStore: CoreStore = useCoreStore();
  let socketRecord: any = null;

  const sendAudio = async (data: UploadFile) => {
    const file = `data:text/plain;filename=audio.webm;base64,${data.file}`;
    const payload = {
      location_id: data.location_id,
      user_id: data.user_id,
      file: file,
      duration: Math.ceil(data.duration! / 1000),
    };
    const message = await handleSendAudioToMessage(payload);
    return message;
  };

  const getAudio = async (payload: any) => {
    const { model_id, id } = payload || {};
    const file = await handlerGetFile({
      model_id,
      model: MODEL.MESSAGE,
      field: id,
    });
    return file;
  };

  const verifySpeaking = async () => {
    const { toggleListen, togglePlay } = useRecord();
    const { getLocation } = useLocation();

    if (registerStore.location.id && registerStore.user.location_id) {
      try {
        const url = `/recording/location/is_record/${registerStore.user.location_id}/${registerStore.user.id}`;

        const speaking: IRecording = await get({ url });

        if (speaking.location_status) {
          if (speaking.location_status.user_name === "location delete") {
            handleMe().then(async (data: IUser) => {
              registerStore.setUser({
                user: data,
              });
              let location = null;
              location = await getLocation(data.location_id ?? -1);
              if (data.location_id) {
                if (location) {
                  registerStore.setLocation(location[0]);
                  await router.push({ name: "home" });
                }
              } else {
                await router.push({ name: "joinNewHome" });
              }
            });
          } else {
            toggleListen({
              nameUser: speaking.location_status.user_name,
              durationAudio: 2,
              status: true,
              recordMode: NOTIFICATION_TYPE.LOCATION,
            });
          }
        } else {
          toggleListen({ status: false });
        }

        if (speaking.user_status.has_notification) {
          const notification = speaking.user_status.has_notification.split("-");
          const location_id = parseInt(notification[1]);
          if (location_id === registerStore.user.location_id) {
            if (notification[0] == NOTIFICATION_TYPE.LOCATION) {
              coreStore.setNotificationMode({
                mode: NOTIFICATION_TYPE.LOCATION,
                id: parseInt(notification[1]),
              });
              togglePlay(true);
              //Reset audio counter
              coreStore.resetTimeWithoutAudio();
            }
          }
          if (notification[0] == NOTIFICATION_TYPE.USER) {
            coreStore.setNotificationMode({
              mode: NOTIFICATION_TYPE.USER,
              id: parseInt(notification[1]),
            });
            togglePlay(true);
            //Reset audio counter
            coreStore.resetTimeWithoutAudio();
          }
        }

        if (speaking.user_status.is_recording) {
          toggleListen({
            nameUser: speaking.user_status.is_recording.user_name,
            durationAudio: 2,
            status: true,
            recordMode: NOTIFICATION_TYPE.USER,
            recordUserId: speaking.user_status.is_recording.user_id,
          });
        }

        ///Play Fake Audio
        if (coreStore.timeWithoutAudio > 120) {
          await playAudioFake();
          coreStore.resetTimeWithoutAudio();
        }

        setTimeout(() => {
          verifySpeaking();
          //Validate time without audio for keep app working in background
          coreStore.setTimeWithoutAudio();
        }, 1000);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const startRecording = async () => {
    const recordStore: RecordStore = useRecordStore();

    if (socketRecord !== null && socketRecord.readyState === WebSocket.OPEN) {
      return;
    }

    let url = `${process.env.VUE_APP_WS_URL}/ws/location/${registerStore.user.location_id}?token=${registerStore.access_token}`;
    if (recordStore.mode == NOTIFICATION_TYPE.USER) {
      url = `${process.env.VUE_APP_WS_URL}/ws/user/${recordStore.userId}?token=${registerStore.access_token}`;
    }
    socketRecord = new WebSocket(url);
  };

  const removeNotification = async (messageId: number) => {
    try {
      await handleRemoveNotification(messageId);
    } catch (e) {
      console.error(e);
    }
  };

  const stopRecording = async () => {
    if (socketRecord) {
      socketRecord.close();
    }
  };

  return {
    sendAudio,
    getAudio,
    verifySpeaking,
    startRecording,
    stopRecording,
    removeNotification,
  };
}
