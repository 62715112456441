import { reactive, toRefs } from "vue";
import { MODEL } from "@/utils/constants";
import { handleSearchReadModel } from "@/services/generic";
import { ILocation } from "@/interfaces/locale/ILocation";
import { ICity } from "@/interfaces/city/ICity";
import { ICountry } from "@/interfaces/country/ICountry";
import { CoreStore, useCoreStore } from "@/stores/core";
import { RegisterStore, useRegisterStore } from "@/stores/register";

export function useLocation() {
  const state = reactive({
    locations: <ILocation[]>[],
    cities: <ICity[]>[],
    countries: <ICountry[]>[],
  });
  const getLocations = async () => {
    const coreStore: CoreStore = useCoreStore();
    const registerStore: RegisterStore = useRegisterStore();
    coreStore.setShowDropdown(false);
    try {
      const payload = {
        model: MODEL.LOCATION,
        fields: [
          "id",
          "name",
          "address",
          "country_id",
          "phone_number",
          "email",
          "local_type",
          "code",
          "entity_id",
          "image_id",
        ],
        filter: [
          {
            field: "entity_id",
            op: "==",
            value: registerStore.user.location_id,
          },
        ],
      };

      handleSearchReadModel(payload).then((data) => {
        state.locations = data.items as ILocation[];
      });
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Get Location By ID
   * @param locationId
   */
  const getLocation = async (locationId: number) => {
    try {
      const payload = {
        model: MODEL.LOCATION,
        fields: [
          "id",
          "name",
          "address",
          "country_id",
          "phone_number",
          "email",
          "local_type",
          "code",
          "entity_id",
          "image_id",
        ],
      };

      const filter = [
        {
          field: "id",
          op: "==",
          value: locationId,
        },
      ];

      const location = await handleSearchReadModel(payload, filter);
      const data = location.items as ILocation[];
      //state.locations = data;
      return data;
    } catch (err) {
      console.error(err);
    }
  };

  const getCities = async (cityId: number) => {
    try {
      const payload = {
        model: MODEL.CITY,
        fields: ["id", "name"],
      };
      const filter = [
        {
          field: "id",
          op: "==",
          value: cityId,
        },
      ];
      handleSearchReadModel(payload, filter).then(async (data) => {
        state.cities = data.items as ICity[];
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getCountries = async (countryId: number) => {
    try {
      const payload = {
        model: MODEL.COUNTRY,
        fields: ["id", "name"],
      };
      const filter = [
        {
          field: "id",
          op: "==",
          value: countryId,
        },
      ];
      handleSearchReadModel(payload, filter).then(async (data) => {
        state.countries = data.items as ICountry[];
      });
    } catch (err) {
      console.error(err);
    }
  };

  return {
    ...toRefs(state),
    getLocations,
    getLocation,
    getCountries,
    getCities,
  };
}
