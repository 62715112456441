export default {
  components: "Components",
  global: {
    help: "Help",
    contact: "Contact",
    goBack: "Back",
    month: "month",
    phone: "Teléfono",
    home: "Home",
    locals: "Teams",
    tasks: "Tasks",
    team: "Team",
    codeCopied: "Code successfully copied",
    shareCodeTitle: "Hi!",
    shareCodeDescription:
      "{name} has invited you to join his team at Wehop. Download the app now, available for {platform}. \n" +
      "Complete the registration and enter the following code to join the team: {code} \n" +
      "https://play.google.com/store/apps/details?id=app.wehop.app&hl=es_ES&gl=ES",
    hi: "Hi!",
    viewDetail: "View Detail",
    leave: "Leave",
    updateSuccess: "Changes saved",
    profileUpdated: "Profile updated",
    dataUpdated: "Data updated",
    rejectSuccess: "Request rejected",
    changesSaved: "Changes saved",
    searchByName: "Search by name",
    today: "Today",
    yesterday: "Yesterday",
    prevDate: "Old",
    logout: "Logout",
    profile: "Profile",
    users: "Users",
    members: "Members",
    owner: "Owner",
    member: "Member",
    manager: "Manager",
    invitations: "Invitations",
    yourTeams: "Your teams",
    unReadMessages: "Audios",
    yourInvitations: "Requests",
    pendingTask: "Tasks",
    notMoreLocations: "Not More teams",
    notMoreLocationsDescription: "To add more teams you must switch to the PRO plan",
    addLocation: "Add",
    deleteUser: "Cancel my account",
    deleteUserHeader: "Are you sure you want to cancel your Wehop account?",
    deleteUserBody: 
      "Remember that once the account is deleted you will not be able to recover any information.",
    notMoreUsers: "You have reached the maximum number of users in your team.",
    notMoreUsersDescription: "To be able to add more users to your team choose one of these two options.",
    addUsers: "Add users",
    changePlan: "Change plan",
    atention: "Atention!",
    createTeamMessage: "To enjoy Wehop the first thing you must do is create your first team.",
    ready: "Ready!",
  },
  actions: {
    cancel: "Cancel",
    save: "Save",
    continue: "Continue",
    skip: "Skip",
    goTo: "Go to home",
    goToHome: "Go to home",
    goToDashboard: "Go to dashboard",
    select: "Select",
    logIn: "LogIn",
    changePhoto: "Change photo",
    copyCode: "Copy code",
    shareCode: "Share business code",
    share: "Share",
    joinTeam: "Join a team",
    validateCode: "Validate Code",
    sendRequest: "Send request",
    joinAnotherTeam: "Join another team",
    viewMyRequest: "View my request",
    update: "Save changes",
    confirm: "Confirm",
    reject: "Reject",
    improvePlan: "Update plan",
    downgradePlan: "Downgrade plan",
    accept: "Accept",
    no: "No",
    confirmDelete: "Yes, confirm",
    buyPlan: "Buy",
    finish: "Finish",
  },
  policy: {
    privacyPolicy: "Policy of Privacy",
    responsible: "Responsible for the treatment",
    data: "Collected data",
    treatment: "Purpose of treatment",
    legitimation: "Legitimation",
    transfers: "International transfers",
    userRights: "User Rights",
    changes: "Changes to WEHOP privacy policy",
    cookies: "Cookies Policy",
  },
  forgotPassword: {
    title: "Have you forgotten your password?",
    description:
      "Don't worry, you can reset your password in three simple steps.",
    phone: "Write your phone number",
    changePassword: "Change your password",
    changePasswordDescription:
      "You're almost there, type in your new password and you're done!",
    newPassword: "New Password",
    repeatPassword: "Repeat password",
    congratulations: "congratulations!",
    congratulationsDescription:
      "Your password has been successfully modified, you can now log in again and enjoy WeHop.",
    goToLogin: "Back to login",
  },
  rules: {
    required: "The field is required",
    email: "Enter a valid email",
    password:
      "Your password must contain a minimum of 8 characters, one capital letter and one special character",
    number: "Please enter a valid number",
    decimal: "Numeric field must have 2 digits after decimal or less",
    max: "The field is must not contain more than {max} characters",
    pinCode: "Write the pin code",
    pinCodeCompleted: "Pin code completed",
    pinCodeNotCompleted: "Pin code not completed",
    passwordMismatch: "Passwords do not match",
  },
  welcome: {
    title: "Welcome",
    subtitle: "Connect, access and share with your team from any location.",
    register: "Register",
    login: "Login",
  },
  login: {
    phone: "Write your phone number",
    forgotPassword: "Forgot password?",
    iDontHave: "I don't have an account yet",
    register: "Register",
    password: "Write your password",
  },
  payment: {
    succes_title: "Payment accepted",
    success_description:
      "Congratulations! You can now start registering all your teams so that you can stay connected with your entire team.",
    unverified_title: "Oops! Something went wrong",
    unverified_description:
      "We were unable to verify your data. Please try again.",
    failed_title: "Payment rejected",
    failed_description:
      "Sorry, we couldn't access the provided data. Please try again later.",
    action_goto_dashboard: "Go to my dashboard",
    action_retry: "Retry",
    action_create_local: "Create new team",
    add_new_user: "Add new user",
    retry_verification: "Error validating data. Please try again.",
    action_downgrade: "Downgrade Plan.",
    planChangeTitle: "Your plan has been successfully changed",
    planChangeUsersTitle: "Up to 5 users!",
    planChangeTeamTitleDescriptionOption: "Remember that you can only have this equipment</br><br><br>You must select from your equipment list which equipment you are going to keep:",
    planChangeTitleDescriptionOption1: "You can now continue to use the functionality of your team.<br>Remember that you can only have this team and you can keep up to 5 users or add new users for an additional cost.",
    planChangeTitleDescriptionOption2: "Remember that you can only have this team.<br>You have a quota of up to 5 users to join your team.<br>Currently, you have {users} users in your team, you must choose whether to:</br>.",
    planChangeTitleDescriptionOption3: "Currently, you have {users} users in your team.</br><br>You will have to select up to 5 users to continue with the operation of your equipment:",
    addUsers: "Add users",
    selectUsers: "Select users",
    downgradeUserFinished: "You can now continue to use the functionalities of your equipment.",
    downgradeTeamFinished: "Ya puedes continuar usando las funcionalidades de tu equipo.</br><br>Se mantendrán todos los usuarios que tienes en {locale_name}.</br><br>Recuerda que siempre puedes comprar más de 5 usuarios.",
  },
  register: {
    continue: "Continue",
    createProfile: "Create my profile",
    createTeam: "Create Team",
    addPhotoToProfile: "Add your photo",
    addPhotoToProfileDescription: "Help your teammates recognize you on WeHop.",
    profileCreated: "Profile created",
    profileCreatedDescription:
      "Congratulations, you are now part of WeHop! You will now be able to stay in touch with your entire team.",
    selectPlan: "Select your plan",
    planInclude: "The plan includes:",
    planLocalUnlimited: "teams unlimited.",
    planLocals: "0 teams. | A single team. | {local} teams.",
    planUserUnlimited: "Users unlimited.",
    planUsers: "{user} Users",
    planUsersTitle: "New Users",
    planMessageUnlimited: "Messages saved unlimited.",
    planIncludeNewUsers: "Select how many user quotas you want to add to your team.",
    planMessages: "Messages stored for {message}.",
    planTaskBoardUnlimited: "Task board unlimited",
    planTaskBoard: "{{taskBoard}} Task board. | {taskBoard} Task board.",
    planCostPerUser: "month per aditional user",
    signUp: "Sign up",
    businessInfo: "Business Info",
    businessInfoDescription:
      "We are creating your business and therefore we would like to know:",
    businessInfoDescriptionFinalStep:
      "Almost there! We just need you to leave us this information about your business:",
    theMostOutstanding: "The most outstanding",
    legalNotice: "Legal Notice",
    privacyPolicy: "Privacy Policy",
    iAccept: "I accept",
    and: "and",
    conditions: "Terms and conditions",
    businessName: "Business Name",
    companyName: "Company Name",
    address: "Address",
    city: "City",
    country: "Country",
    email: "Email",
    corporateEmail: "Corporate Email",
    phone: "Teléfono",
    phonePrefix: "Prefix",
    locals: "Team numbers",
    employees: "Members numbers",
    uploadPhoto: "Edit photo",
    whatDoYouWantToDo: "What do you want to do?",
    fullName: "Full Name",
    firstName: "First Name",
    lastName: "Last name",
    birthday: "Birthday",
    verificationCode: "Verification Code",
    verificationCodeDescription:
      "We have sent you an SMS with a verification code to the phone number you have given us, please enter the code:",
    changePassword: "Change your Password",
    changePasswordMessage:
      "You're almost there, type in your new password and you're done!",
    term: {
      objective: "Objective",
      aboutUs: "About Us",
      whatIsWeHop: "What is WeHop",
      conditions: "Conditions of use of our App and website",
      contract: "Condiciones de contratación de nuestros servicios",
      features: "How WeHop works",
      employee: "User terms of use with your members",
      price: "Price, payment and payment method",
      responsibility: "Responsibility",
      resolution: "Resolution",
      dataProtection: "Data protection",
      industrialProtection: "Intellectual and industrial protection",
      modifyConditions: "Changes in conditions",
      law: "Applicable law and jurisdiction",
      privacyPolicy: "Policy of Privacy",
    },
    policy: {
      privacyPolicy: "Policy of Privacy",
      privacyPolicyMessage:
        "I accept the privacy policy and terms and conditions.",
      responsible: "Responsible for the treatment",
      data: "Collected data",
      treatment: "Purpose of treatment",
      legitimation: "Legitimation",
      transfers: "International transfers",
      userRights: "User Rights",
      changes: "Changes to WEHOP privacy policy",
      cookies: "Cookies Policy",
      dataRetentionPeriod: "Data retention period",
      security: "Security measures applied to your data",
    },
    convertToOwner: "Convert to owner",
    downgradePlan: "Downgrade plan",
  },
  group: {
    selectGroup: "Select a group",
    copy: "Copy code",
    share: "Share business code",
    members: "Members",
    requests: "Requests",
    detail: "Detail",
    edit: "Edit user",
    delete: "Delete",
    deleteUserConfirm: "Are you sure you want to eliminate him?",
    deleteUserConfirmMessage:
      "By deleting the user you will lose their personal information and you will no longer be able to contact them within the app.",
    rememberLimitUsers: "Remember that you can only select up to 5 users",
    rememberLimitTeams: "Remember that you can only select up to 1 teams"
  },
  joinTeam: {
    joinATeam: "Join a team",
    youKnowCode: "Do you know the code?",
    youKnowCodeDescription:
      "To join a team, enter the code you were previously sent below and send them a request. \nThe team owner must accept your request to join and enjoy the application features.",
    whiteCode: "White the team code",
    youWillJoin: "You will join the team",
    joinConfirm:
      "Confirm your request to join the team and wait for the administrator's confirmation.",
    joinConfirmSecond:
      "You will automatically access the equipment once your application is accepted.",
    requestSent: "Request sent",
    requestSentMessage:
      "You will automatically access the equipment once your application is accepted.",
    myWorkspaces: "My Workspaces",
    actives: "Actives",
    requests: "Requests",
    requestWrong: "¡Ups! Something went wrong",
    requestWrongMessage:
      "The login request has not been completed. Please try again later.",
    code: "Code",
    status: "Status",
    userId: "User Id",
    requestWaitingMessage:
      "Your application to join the {name} team is awaiting approval.",
    requestRejectedMessage:
      "Your application to join the {name} team was rejected.",
    leaveTeam: "Are you sure you want to leave?",
    leaveTeamDescription:
      "Once you leave the premises, you will no longer be able to access any section of the premises, and you will be disassociated.",
    employee: "Member",
    manager: "Manager",
    assignRole: "Assign a role to the member",
    assignRoleDescription:
      "As a business owner, you can change the member's role at any time.",
    ownersRole: "Owners",
    managersRole: "Managers",
    employeesRole: "Members",
    sector: "Business Sector",
  },
  myProfile: {
    profile: "Profile",
    myProfile: "My Profile",
    myData: "My data",
    myBusiness: "My business",
    myTeams: "My Teams",
    setting: "Setting",
    logout: "Logout",
    myCurrentPlan: "My current plan",
    updateData: "Update data",
    commercialName: "Commercial name",
    name: "Corporate name",
    address: "Address",
    city: "City",
    country: "Country",
    vat: "CIF/NIF",
    phone: "Phone",
    email: "Corporate Email",
  },
  locale: {
    localeDescriptionAdd:
      " will be able to change the name later. The address will be used to help you differentiate businesses with the same name.",
    localeCreated: "Team created",
    adminLocales: "Admin Teams",
    localeManager: "Team Manager",
    editLocale: "Edit team",
    newLocale: "New team",
    createLocale: "Create local",
  },
  talkie: {
    generalChannel: "General Channel for team",
    detail: "Detail",
    showButton: "Show floating button",
    markAsRead: "Mark as read",
    talkingTo: "Talking to",
    isTalkingToYou: "is talking to you",
    yourTalks: "Your conversations",
    searchByName: "Search by name",
    markAsListened: "Mark as listened",
  },
  tasks: {
    list: "Task List",
    filter: "Filter tasks",
    pending: "Pending",
    finished: "Finished",
    priority: "Priority",
    priorities: "Priorities",
    no_priorities: "No priorities",
    all: "All",
    assigned_tasks: "Assigned tasks",
    no_assigned: "No assigned",
    assigned: "Assigned",
    assigned_to: "Assigned to",
    edit: "Edit task",
    delete: "Delete task",
    created_by: "Created by",
    finish: "Finish task",
    fail_finish: "An error occurred while completing the task",
    success_finished: "The task has been completed successfully",
    fail_remove: "An error occurred while deleting the task",
    success_removed: "The task has been successfully deleted",
    finished_task: "Finished",
    updated_task: "The task has been successfully updated",
    remove_description:
      "You will lose all recorded information and will not be able to edit these items again",
    remove_title: "You are going to remove the task from the list",
    finalized_by: "Finalized by",
    addTask: "Create task",
    priorityTask: "Priority task",
  },
  advice: {
    addAdvice: "Create advices",
    edit: "Edit notice",
    delete: "Delete notice",
    success_removed: "The notice has been successfully deleted",
    fail_remove: "An error occurred while deleting the notice",
    updated_advice: "The notice has been successfully updated",
  },
  navigation: {
    home: "Home",
    talkie: "Talkie",
    tasks: "Tasks",
  },
  notification: {
    talkieMessage: "You have a new audio",
    newTaskAssignedMessage: "New task assigned",
  },
  tour: {
    tour1_1: {
      title: "Owner's personal information",
      text:
        "¡Vamos a conocernos!\n" +
        "Lo primero que debes hacer es crear una cuenta, para ello completa tus datos personales a continuación, valida tu identidad y selecciona el plan que mejor se adapte a tus preferencias",
    },
    tour1_2: {
      title: "Crea tu primer Equipo",
      text:
        "¡Que bien!\n" +
        "Ya tu cuenta se ha creado satisfactoriamente. El siguiente paso, para poder utilizar todas las funcionalidades que te ofrece Wehop, será crear tu primer equipo, para eso solo tienes que completar el siguiente formulario",
    },
    tour1_3: {
      title: "Datos personales del miembro",
      text: "¡Vamos a conocernos!<br> Lo primero que debes hacer es crear una cuenta, para ello completa tus datos personales a continuación y valida tu identidad.",
    },
    tour1_4: {
      title: "Unirte a un equipo",
      text: "Para unirte a un equipo, debes ingresar el código correspondiente que te ha enviado previamente el propietario de ese equipo y enviarle una solicitud para unirte",
    },
    tour2_1: {
      title: "Crear más equipos",
      text: "Cuando quieras crear más equipos deberás dirigirte sobre el módulo de Equipos en el menú principal.",
    },
    tour2_2: {
      title: "Crear más equipos",
      text: "A través del ícono azul te llevará automáticamente a rellenar el formulario para crear un nuevo local. Recuerda que con el plan Pro podrás crear tantos equipos como tú quieras.",
    },
    tour2_3: {
      title: "Opciones de un equipo",
      text: "Para poder gestionar tus equipos tendrás varias opciones pulsando en este ícono: <ul><li>Podrás editar algún dato de tu equipo</li><li>Podrás eliminar un equipo que hayas creado con anterioridad.</li><li>Además, te permite invitar a otros usuarios a unirse a tus equipos. Deberán unirse a través de un código de invitación.</li></ul> Para acceder a ese código tienes dos maneras:",
    },
    tour2_4: {
      title: "Invitar miembros a tus equipos",
      text: '1. Puedes pulsar sobre <strong>"Copiar código"</strong>,y pegar ese código en otra aplicación.',
    },
    tour2_5: {
      title: "Invitar miembros a tus equipos",
      text: '2. También, puedes enviar el código a otro miembro a través de otra app que tengas en tu móvil, pulsando sobre la opción de <strong>"Compartir código"</strong>',
    },
    tour2_6: {
      title: "Eliminar un equipo",
      text: "Al <strong style='color=red'>eliminar un equipo</strong> recuerda que perderás toda la información y los miembros asociados a ese equipo",
    },
    tour3_1: {
      title: "Tablero de tareas",
      text: "Para poder consultar y gestionar las tareas y avisos creados en tu equipo debes dirigirte al módulo de Tareas",
    },
    tour3_2: {
      title: "Crear tareas o avisos",
      text: "Para crear una nueva tarea o aviso debes pulsar sobre este ícono, dependiendo de la opción que selecciones te llevará al formulario correspondiente",
    },
    tour3_3: {
      title: "Opciones de una tarea",
      text: "Dentro de una tarea tendrás múltiples opciones como: <ul><li>Editarla</li><li>Eliminarla</li><li>Asignarsela a un miembro de tu equipo</li><li>Comentar dentro de la tarea</li><li>Finalizar la tarea.</li></ul>",
    },
    tour4_1: {
      title: "Módulo de Miembros",
      text: "Te contamos un poco sobre los roles de la aplicación: Propietario: es el usuario dueño del equipo <ul><li>Administrador: son aquellos miembros que pueden forman parte de tu equipo pero tienen el privilegio de gestionar información y demás miembros.</li><li>Miembros: son todos los usuarios que se han unido a tu equipo</li></ul>",
    },
    tour4_2: {
      title: "Opciones de un miembro",
      text: "Cada miembro que forme parte de tu equipo tendrá diferentes opciones como: <ul><li>En Editar podrás cambiar el rol que tenga el miembro</li><li>Podrás eliminar un miembro de tu equipo.</li></ul>",
    },
    tour4_3: {
      title: "Eliminar un miembro",
      text: "Si <strong style='color:red'>eliminas un miembro</strong> de tu equipo, ya no podrá acceder a ninguna funcionalidad ni información de tu equipo.",
    },
    tour4_4: {
      title: "Invite members to your teams",
      text: "You can click on Copy code, and paste that code into another application.",
    },
    tour4_5: {
      title: "Invite members to your teams",
      text: "You can also send the code to another member through another app you have on your cell phone, by clicking on the Share code option.",
    },
    tour5_1: {
      title: "Talkie",
      text: "En el módulo de Talkie podrás comunicarte con otros miembros a través de audios que formen parte de tu equipo",
    },
    tour5_2: {
      title: "Enviar mensajes de voz grupales",
      text: "Para enviarle audios a todos los miembros que forman parte de tu equipo, sigue estos pasos: <ol><li>Pulsa una vez el botón de audio para que se active la grabación</li><li>Habla con total normalidad</li><li>Vuelve a pulsar sobre el botón de audio para detener la grabación y enviar el audio.</li>",
    },
    tour5_3: {
      title: "Botón de grabación",
      text: "Recuerda que si un miembro de tu equipo está grabando un audio el botón se bloqueará hasta que este miembro termine su grabación",
    },
    tour5_4: {
      title: "Enviar mensajes de voz individuales",
      text: "También podrás comunicarte con un miembro específico. Para eso debes: <ol><li>Pulsa una vez el avatar del miembro que quieres enviarle el audio para que se active la grabación</li><li>Habla con total normalidad</li><li>Vuelve a pulsar sobre el avatar para detener la grabación y enviar el audio.</li></ol>",
    },
    tour5_5: {
      title: "Botón flotante",
      text: "Utilizar el botón flotante te permite enviar audios a todos los miembros de tu equipo y a la vez poder estar navegando en otras apps de tu móvil",
    },
    tour5_6: {
      title: "Botón flotante",
      text: "Debes habilitar en tu móvil el funcionamiento del botón flotante. Una vez habilitado debes: <ol><li>Pulsa una vez el botón de audio para que se active la grabación</li><li>Habla con total normalidad</li><li>Vuelve a pulsar sobre el botón de audio para detener la grabación y enviar el audio.</li></ol>",
    },
    tour5_7: {
      title: "Chats",
      text: "Trough this icon you will be able to access the chats, both group and individual.",
    },
    tour5_8: {
      title: "Message thread",
      text: "In each chat you will be able to see the audios that have been sent.",
    },
  },
};
