import {reactive, toRefs} from "vue";
import {IForgotPassword} from "@/interfaces/forgot_password/IForgotPassword";
import {ISMSResponse} from "@/interfaces/forgot_password/ISMSResponse";
import {SMS_TYPE} from "@/utils/constants";
import {handleSendPassword, handleSendPhone} from "@/services/forgot_password";
import {CoreStore, useCoreStore} from "@/stores/core";
import router from "@/router";

export function useForgotPassword() {

    const coreStore: CoreStore = useCoreStore()

    const state = reactive<IForgotPassword>({
        pin: undefined,
        password: '',
        confirmPassword: '',
        phone: undefined,
        phonePrefix: undefined,
        phoneSent: false,
        pinSaved: false,
        passwordSent: false,
        subjectSMS: SMS_TYPE.FORGOT_PASSWORD,
    });
    const sendPhone = async () => {
        try {
            state.phoneSent = false;
            handleSendPhone({
                prefix_number: state.phonePrefix!.toString(),
                phone_number: state.phone!.toString(),
                subject: state.subjectSMS,
            }).then(async (data: ISMSResponse) => {
                if (data.detail == 'SMS Sended') {
                    state.phoneSent = true;
                    coreStore.nextHelpStep(2);
                    coreStore.setPhone(state.phone!.toString(), state.phonePrefix!.toString());
                    await router.push({name: "pinCode"});
                } else {
                    state.phoneSent = false;
                }
            })
        } catch (e) {
            console.error(e);
        }
    };

    const savePin = async () => {
        try {
            coreStore.nextHelpStep(3);
            coreStore.setPin(state.pin!.toString());
            await router.push({name: "changePassword"});
            state.pinSaved = true;
        } catch (e) {
            console.error(e);
        }
    };

    const sendPassword = async () => {
        try {
            handleSendPassword({
                prefix_number: coreStore.prefixNumber,
                phone_number: coreStore.phone,
                new_password: state.password,
                code: coreStore.pin,
            }).then(async (data: ISMSResponse) => {
                if (data.detail == 'Password changed') {
                    state.passwordSent = true;
                    coreStore.resetHelpStep();
                    await router.push({name: "changePasswordMessage"});
                } else {
                    state.passwordSent = false;
                }
            })
        } catch (e) {
            console.error(e);
        }
    };

    return {
        ...toRefs(state),
        sendPhone,
        savePin,
        sendPassword
    };
}
