import { defineStore } from "pinia";

type GenericState = {
  generic: {
    [name: string]: any;
  };
  refresh: boolean
};

export interface GenericActions {
  setSortByModel: (model: string, orderBy: any[]) => void;
  setFiltersModel: (model: string, filters: any[]) => void;
  setItemsModel: (model: string, items: any[]) => void;
  setHeadersModel: (model: string, headers: any[]) => void;
  setPaginationModel: (model: string, pagination: any) => void;
  setRefresh: (refreshList: boolean) => void;
}

export const useGenericStore = defineStore({
  id: "generic",
  state: (): GenericState => ({
    generic: {},
    refresh: false,
  }),
  actions: {
    setFiltersModel(this: any, model: string, filters: any = []) {
      this.generic[`filters_${model}`] = filters;
    },
    setItemsModel(this: any, model: string, items: any = []) {
      this.generic[`items_${model}`] = items;
    },
    setHeadersModel(this: any, model: string, headers: any = []) {
      this.generic[`headers_${model}`] = headers;
    },
    setPaginationModel(this: any, model: string, pagination: any) {
      this.generic[`pagination_${model}`] = pagination;
    },
    setSortByModel(this: any, model: string, orderBy: any) {
      this.generic[`sortBy_${model}`] = orderBy;
    },
    setRefresh(this: any, refreshList: boolean) {
      this.refresh = refreshList;
    },
  } as GenericActions,
});

export type GenericStore = ReturnType<typeof useGenericStore>;
