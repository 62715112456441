import {reactive, toRefs} from "vue";
import {MODEL} from "@/utils/constants";
import {handleSearchReadModel} from "@/services/generic";
import {IUserLocation} from "@/interfaces/user_location/IUserLocation";

export function useUserLocation() {

    const state = reactive({
        locations: <IUserLocation[]>[],
        total: 0,

    });
    const getUserLocations = async (locationId?: number) => {
        try {
            const payload = {
                model: MODEL.USERLOCATION,
                fields: ['location_id', 'user_id'],
            };
            const filter = [
                {
                    field: 'location_id',
                    op: "==",
                    value: locationId ?? -1,
                },
            ];
            const data = await handleSearchReadModel(payload, filter);
            state.locations = data.items as IUserLocation[];
            state.total = data.total;
            return state.locations;
        } catch (err) {
            console.error(err);
        }
    };

    return {
        ...toRefs(state),
        getUserLocations,
    };
}
