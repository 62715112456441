import { api } from "@/plugins/axios";
import { toastController } from "@ionic/vue";
export const post = async (data: any) => {
  const {
    url,
    payload,
    errMessage = null,
    API_TEST = false,
    functionTest = null,
    params = null,
    config = null,
  } = data;
  try {
    if (!API_TEST) {
      const { data } = await api.post(url, payload, config);
      return data;
    } else {
      const data = await functionTest(params);
      return data;
    }
  } catch (err) {
    if (errMessage) {
      const toast = await toastController.create({
        message: errMessage,
        duration: 1500,
        position: "bottom",
      });
      await toast.present();
    }
    throw err;
  }
};

export const put = async (data: any) => {
  const {
    url,
    payload,
    errMessage = null,
    API_TEST = false,
    functionTest = null,
    params = null,
  } = data;
  try {
    if (!API_TEST) {
      const { data } = await api.put(url, payload);
      return data;
    } else {
      const data = await functionTest(params);
      return data;
    }
  } catch (err) {
    if (errMessage) {
      const toast = await toastController.create({
        message: errMessage,
        duration: 1500,
        position: "bottom",
      });
      await toast.present();
    }
    throw err;
  }
};

export const patch = async (data: any) => {
  const {
    url,
    payload,
    errMessage = null,
    API_TEST = false,
    functionTest = null,
    params = null,
  } = data;
  try {
    if (!API_TEST) {
      const { data } = await api.patch(url, payload);
      return data;
    } else {
      const data = await functionTest(params);
      return data;
    }
  } catch (err) {
    if (errMessage) {
      const toast = await toastController.create({
        message: errMessage,
        duration: 1500,
        position: "bottom",
      });
      await toast.present();
    }
    throw err;
  }
};

export const get = async (data: any) => {
  const {
    url,
    errMessage = null,
    params = null,
    API_TEST = false,
    functionTest = null,
  } = data;
  try {
    if (!API_TEST) {
      const { data } = await api.get(url, params);
      return data;
    } else {
      const data = await functionTest(params);
      return data;
    }
  } catch (err) {
    if (errMessage) {
      const toast = await toastController.create({
        message: errMessage,
        duration: 1500,
        position: "bottom",
      });
      await toast.present();
    }
    throw err;
  }
};

export const remove = async (data: any) => {
  const { url, errMessage = null } = data;
  try {
    const { data } = await api.delete(url);
    return data;
  } catch (err) {
    if (errMessage) {
      const toast = await toastController.create({
        message: errMessage,
        duration: 1500,
        position: "bottom",
      });
      await toast.present();
    }
    throw err;
  }
};
