import { get, post } from "@/services/api";

export const handleSendUserInfo = async (payload: object) => {
  return await post({
    url: "signup",
    payload: payload,
  });
};

export const handlerUploadPhoto = async (photo: string, userId: number) => {
  return await post({
    url: `user/${userId}/filebase/image_id`,
    payload: {
      file: photo,
    },
  });
};

export const handlerUploadLocalePhoto = async (
  photo: string,
  locationId: number
) => {
  return await post({
    url: `location/${locationId}/filebase/image_id`,
    payload: {
      file: photo,
    },
  });
};

export const handlerGetPhoto = async (userId: number, imageId: number, url: boolean) => {
  return await get({
    url: `user/${userId}/file/${imageId}?url=${url}`,
    payload: {},
  });
};

export const handlerGetLocalePhoto = async (
  localeId: number,
  imageId: number,
  url: boolean
) => {
  return await get({
    url: `location/${localeId}/file/${imageId}?url=${url}`,
    payload: {},
  });
};

export const handlerSendInvitation = async (
  phoneNumber: string,
  localCode: string
) => {
  return await post({
    url: `send/invitation-link`,
    payload: {
      phone_number: phoneNumber,
      local_code: localCode,
    },
  });
};
