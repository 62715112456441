import {createRouter, createWebHistory} from "@ionic/vue-router";
import {RouteRecordRaw} from "vue-router";
import TabsPage from "@/views/TabsPage.vue";
import IndexDocs from "@/views/docs/IndexView.vue";
import ListDocs from "@/views/docs/ListView.vue";
import DocsLayout from "@/layouts/DocsLayout.vue";
import LoginView from "@/views/auth/LoginView.vue";
import WelcomeView from "@/views/WelcomeView.vue";
import RegisterView from "@/views/register/RegisterView.vue";
import ForgotPasswordView from "@/views/forgot_password/ForgotPasswordView.vue";
import PinCodeView from "@/views/forgot_password/PinCodeView.vue";
import ChangePasswordView from "@/views/forgot_password/ChangePasswordView.vue";
import ChangePasswordMessageView from "@/views/forgot_password/ChangePasswordMessageView.vue";
import RegisterEmployeeView from "@/views/register/RegisterEmployeeView.vue";
import RegisterStoreView from "@/views/register/RegisterStoreView.vue";
import RegisterPinCodeView from "@/views/register/RegisterPinCodeView.vue";

import AdminIndexView from "@/views/admin/IndexView.vue";
import EmployeeIndexView from "@/views/employee/IndexView.vue";
import locationListView from "@/views/admin/location/CrudView.vue";
import localeUploadPhotoView from "@/views/admin/location/LocaleUploadPhotoView.vue";
import GroupListView from "@/views/admin/group/GroupListView.vue";
import TalkieListView from "@/views/admin/talkie/TalkieListView.vue";
import RegisterPhotoView from "@/views/register/RegisterPhotoView.vue";
import RegisterProfileCreatedView from "@/views/register/RegisterProfileCreatedView.vue";
import RegisterPasswordView from "@/views/register/RegisterPasswordView.vue";
import RegisterPlanView from "@/views/register/RegisterPlanView.vue";
import RegisterPlanDowngradeView from "@/views/register/RegisterPlanDowngradeView.vue";
import RegisterBusinessInfoView from "@/views/register/RegisterBusinessInfoView.vue";
import PrivacyPolicyView from "@/views/PrivacyPolicyView.vue";
import TermConditionView from "@/views/TermConditionView.vue";
import RegisterBusinessInfoFinalStepView from "@/views/register/RegisterBusinessInfoFinalStepView.vue";
import RegisterUploadPhotoView from "@/views/register/RegisterUploadPhotoView.vue";
import JoinHomeView from "@/views/join_team/JoinHomeView.vue";
import JoinValidateCodeView from "@/views/join_team/JoinValidateCodeView.vue";
import JoinPreviewView from "@/views/join_team/JoinPreviewView.vue";
import JoinRequestSentView from "@/views/join_team/JoinRequestSentView.vue";
import JoinRequestListView from "@/views/join_team/JoinRequestListView.vue";
import JoinRequestWrongView from "@/views/join_team/JoinRequestWrongView.vue";
import MyProfileOwnerView from "@/views/my_profile/MyProfileOwnerView.vue";
import MyProfileManagerView from "@/views/my_profile/MyProfileManagerView.vue";
import MyProfileEmployeeView from "@/views/my_profile/MyProfileEmployeeView.vue";
import {useCoreStore} from "@/stores/core";
import {useNativePlatform} from "@/composables/useNativePlatform";
import TalkieLocaleAudioView from "@/views/admin/talkie/TalkieLocaleAudioView.vue";
import TaskListView from "@/views/admin/tasks/TaskListView.vue";
import TalkiePersonalAudioView from "@/views/admin/talkie/TalkiePersonalAudioView.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import SuccessPayment from "@/views/payment_result/SuccessPaymentView.vue"
import SuccessPaymentUser from "@/views/payment_result/SuccessPaymentUserView.vue"
import FailedPayment from "@/views/payment_result/FailedPaymentView.vue"
import UnverifiedPayment from "@/views/payment_result/UnverifiedPayment.vue"
import BusinessUpdateInfoView from "@/views/my_profile/BusinessUpdateInfoView.vue";
import JoinRequestSentInitialView from "@/views/join_team/JoinRequestSentInitialView.vue";
import JoinRequestListInitialView from "@/views/join_team/JoinRequestListInitialView.vue";
import RegisterPlanDowngradeUsersView from "@/views/register/RegisterPlanDowngradeUsersView.vue";
import RegisterPlanDowngradeTeamsView from "@/views/register/RegisterPlanDowngradeTeamsView.vue";
import RegisterPlanDowngradeOptionOneView from "@/views/register/RegisterPlanDowngradeOptionOneView.vue";
import RegisterPlanDowngradeOptionTwoView from "@/views/register/RegisterPlanDowngradeOptionTwoView.vue";

import {isLoggedIn} from "@/utils/docs/isLoggedIn";
import {useRegister} from "@/composables/useRegister";
import {ROLES} from "@/utils/constants";
import JoinNewHomeView from "@/views/join_team/JoinNewHomeView.vue";
import useCore from "@/composables/useCore";
import RegisterPlanDowngradeUsersFinishView from "@/views/register/RegisterPlanDowngradeUsersFinishView.vue";
import RegisterPlanDowngradeTeamsFinishView from "@/views/register/RegisterPlanDowngradeTeamsFinishView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "root",
        component: WelcomeView,
        meta: {
            auth: false,
        },
    },
    {
        path: "/register/",
        component: RegisterView,
        meta: {
            auth: false,
        },
    },
    {
        path: "/register/employee", // Here
        name: "registerEmployee",
        component: RegisterEmployeeView,
        meta: {
            auth: false,
        },
        beforeEnter: (to, from, next) => {
            const { setResetForm, resetForm } = useCore();
            setResetForm(!resetForm.value);
            next();
        },
    },
    {
        path: "/register/pin-code", // Here
        name: "registerPinCode",
        component: RegisterPinCodeView,
        meta: {
            auth: false,
        },
        beforeEnter: (to, from, next) => {
            const { setResetPinDigit, resetPinDigit } = useCore();
            setResetPinDigit(!resetPinDigit.value);
            next();
        },
    },
    {
        path: "/register/photo",
        name: "registerEmployeePhoto",
        component: RegisterPhotoView,
        meta: {
            auth: true,
        },
    },
    {
        path: "/register/upload-photo",
        name: "registerEmployeeUploadPhoto",
        component: RegisterUploadPhotoView,
        meta: {
            auth: true,
        },
    },
    {
        path: "/register/profile-created",
        name: "registerProfileCreated",
        component: RegisterProfileCreatedView,
        meta: {
            auth: true,
        },
    },
    {
        path: "/register/store",
        name: "registerStore",
        component: RegisterStoreView,
        meta: {
            auth: false,
        },
    },
    {
        path: "/register/password",
        name: "registerPassword",
        component: RegisterPasswordView,
        meta: {
            auth: false,
        },
    },
    {
        path: "/register/plan",
        name: "registerPlan",
        component: RegisterPlanView,
        meta: {
            auth: true,
        },
    },
    {
        path: "/register/plan/downgrade",
        name: "registerPlanDowngrade",
        component: RegisterPlanDowngradeView,
        meta: {
            auth: true,
        },
    },
    {
        path: "/register/plan/downgrade/option/one",
        name: "registerPlanOptionOneDowngrade",
        component: RegisterPlanDowngradeOptionOneView,
        meta: {
            auth: true,
        },
    },
    {
        path: "/register/plan/downgrade/option/two",
        name: "registerPlanOptionTwoDowngrade",
        component: RegisterPlanDowngradeOptionTwoView,
        meta: {
            auth: true,
        },
    },
    {
        path: "/register/plan/downgrade/users",
        name: "registerPlanUsersDowngrade",
        component: RegisterPlanDowngradeUsersView,
        meta: {
            auth: true,
        },
    },
    {
        path: "/register/plan/downgrade/users/finish",
        name: "registerPlanUsersFinishDowngrade",
        component: RegisterPlanDowngradeUsersFinishView,
        meta: {
            auth: true,
        },
    },
    {
        path: "/register/plan/downgrade/teams",
        name: "registerPlanTeamsDowngrade",
        component: RegisterPlanDowngradeTeamsView,
        meta: {
            auth: true,
        },
    },
    {
        path: "/register/plan/downgrade/teams/finish",
        name: "registerPlanTeamsFinishDowngrade",
        component: RegisterPlanDowngradeTeamsFinishView,
        meta: {
            auth: true,
        },
    },
    {
        path: "/payment/success",
        name: "successPayment",
        component: SuccessPayment,
        meta: {
            auth: true,
        },
    },
    {
        path: "/payment/user/success",
        name: "successUserPayment",
        component: SuccessPaymentUser,
        meta: {
            auth: true,
        },
    },
    {
        path: "/payment/unverified",
        name: "unverifiedPayment",
        component: UnverifiedPayment,
        meta: {
            auth: true,
        },
    },
    {
        path: "/payment/failed",
        name: "failedPayment",
        component: FailedPayment,
        meta: {
            auth: true,
        },
    },
    {
        path: "/register/policy-privacy",
        name: "registerPolicyPrivacy",
        component: PrivacyPolicyView,
        meta: {
            auth: false,
        },
    },
    {
        path: "/register/terms",
        name: "registerTerms",
        component: TermConditionView,
        meta: {
            auth: false,
        },
    },
    {
        path: "/register/business-info",
        name: "registerBusinessInfo",
        component: RegisterBusinessInfoView,
        meta: {
            auth: true,
        },
    },
    {
        path: "/register/business-info-final",
        name: "registerBusinessInfoFinalStep",
        component: RegisterBusinessInfoFinalStepView,
        meta: {
            auth: true,
        },
    },
    {
        path: "/forgot-password",
        component: ForgotPasswordView,
        meta: {
            auth: false,
        },
    },
    {
        path: "/pin-code",
        name: "pinCode",
        component: PinCodeView,
        meta: {
            auth: false,
        },
    },
    {
        path: "/change-password",
        name: "changePassword",
        component: ChangePasswordView,
        meta: {
            auth: false,
        },
    },
    {
        path: "/change-password-message",
        name: "changePasswordMessage",
        component: ChangePasswordMessageView,
        meta: {
            auth: false,
        },
    },
    {
        path: "/auth/login",
        name: "login",
        component: LoginView,
        meta: {
            auth: false,
        },
        beforeEnter: (to, from, next) => {
            const {isMobile} = useNativePlatform();
            if (!isMobile) {
                if (
                    localStorage.getItem("access_token") &&
                    localStorage.getItem("is_logged")
                ) {
                    const {setLogout} = useCoreStore();
                    if (!from.name || from.name === "admin.home") {
                        setLogout(true);
                    }
                    next({name: "admin.home"});
                } else {
                    next();
                }
            } else {
                next();
            }
        },
    },
    {
        path: "/tabs/",
        component: TabsPage,
        children: [
            {
                path: "",
                redirect: "/tabs/tab1",
                meta: {
                    auth: true,
                },
            },
            {
                path: "tab1",
                component: () => import("@/views/Tab1Page.vue"),
                meta: {
                    auth: true,
                },
            },
            {
                path: "tab2",
                component: () => import("@/views/Tab2Page.vue"),
                meta: {
                    auth: true,
                },
            },
            {
                path: "tab3",
                component: () => import("@/views/Tab3Page.vue"),
                meta: {
                    auth: true,
                },
            },
        ],
    },
    {
        path: "/docs",
        component: DocsLayout,
        children: [
            {
                path: "",
                name: "docs.form",
                component: IndexDocs,
                meta: {
                    auth: true,
                },
            },
            {
                path: "list",
                name: "docs.list",
                component: ListDocs,
                meta: {
                    auth: true,
                },
            },
        ],
    },
    {
        path: "/home",
        name: "home",
        component: DefaultLayout,
        children: [
            {
                path: "",
                name: "admin.home",
                component: AdminIndexView,
                meta: {
                    auth: true,
                },
                beforeEnter: (to, from, next) => {
                    const {setRefreshDashboardOwner, refreshDashboardOwner} = useCore();
                    setRefreshDashboardOwner(!refreshDashboardOwner.value);
                    next();
                },
            },
            {
                path: "/dashboard",
                name: "employee.home",
                component: EmployeeIndexView,
                meta: {
                    auth: true,
                },
                beforeEnter: (to, from, next) => {
                    const {setRefreshDashboard, refreshDashboard} = useCore();
                    setRefreshDashboard(!refreshDashboard.value);
                    next();
                },
            },
            {
                path: "/employee/join/list",
                name: "employee.join",
                component: JoinRequestSentView,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/location",
                name: "admin.locations.list",
                component: locationListView,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/locale/upload-photo",
                name: "admin.locations.upload",
                component: localeUploadPhotoView,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/groups",
                name: "admin.groups.list",
                component: GroupListView,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/talkie",
                name: "default.talkie.list",
                component: TalkieListView,
                meta: {
                    auth: true,
                },
                beforeEnter: (to, from, next) => {
                    const {setRefreshNotification, refreshNotification} = useCore();
                    setRefreshNotification(!refreshNotification.value);
                    next();
                },
            },
            {
                path: "/talkie/locale/audio",
                name: "default.talkie.locale.audio",
                component: TalkieLocaleAudioView,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/talkie/personal/audio/:id",
                name: "default.talkie.personal.audio",
                component: TalkiePersonalAudioView,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/tasks",
                name: "default.tasks.list",
                component: TaskListView,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/join",
                name: "joinHome",
                component: JoinHomeView,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/join/new",
                name: "joinNewHome",
                component: JoinNewHomeView,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/join/validate",
                name: "joinValidateCode",
                component: JoinValidateCodeView,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/join/preview",
                name: "joinTeamPreview",
                component: JoinPreviewView,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/join/sent",
                name: "joinRequestSent",
                component: JoinRequestSentInitialView,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/join/wrong",
                name: "joinRequestWrong",
                component: JoinRequestWrongView,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/join/list",
                name: "joinRequestList",
                component: JoinRequestListView,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/join/list/initial",
                name: "joinRequestInitialList",
                component: JoinRequestListInitialView,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/my/profile/owner",
                name: "myProfileOwner",
                component: MyProfileOwnerView,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/my/profile/manager",
                name: "myProfileManager",
                component: MyProfileManagerView,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/my/profile/employee",
                name: "myProfileEmployee",
                component: MyProfileEmployeeView,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/business/update",
                name: "businessUpdateInfoView",
                component: BusinessUpdateInfoView,
                meta: {
                    auth: true,
                },
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = 'Wehop';
    const {user} = useRegister();
    const requiresAuth = to.meta.auth
    if (requiresAuth && !isLoggedIn()) next({name: 'login'});
    if (to.name == 'root' && isLoggedIn()) {
        if (user.value.role == ROLES.OWNER) {
            next({name: 'admin.home'});
        } else {
          if (user.value.location_id !== null ) {
            next({name: 'employee.home'});
          } else {
            next({name: 'joinHome'});
          }
        }
    }
    next();
});

export default router;
