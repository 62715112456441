import { MODEL, NOTIFICATION_TYPE } from "@/utils/constants";
import { handleDeleteModel, handleSearchReadModel } from "./generic";
import { useRegister } from "@/composables/useRegister";
import { useAudio } from "@/composables/useAudio";
import useRecord from "@/composables/useRecord";
import { post } from "@/services/api";
import { handlerNotificationQueue } from "@/services/message";
import { CoreStore, useCoreStore } from "@/stores/core";
import { INotification } from "@/interfaces/notification/INotification";

const audioFiles: any[] = [];

export const getListUsers = async (location_id: any, search?: string) => {
  const { user, getPhoto } = useRegister();

  const payloadUserlocation = {
    model: MODEL.USERLOCATION,
    fields: ["location_id", "user_id"],
  };
  const filtersUserlocation = [
    {
      field: "location_id",
      op: "==",
      value: location_id,
    },
  ];
  const userLocations = await handleSearchReadModel(
    payloadUserlocation,
    filtersUserlocation
  );

  const mapUserLocations = userLocations.items.map((item: any) => item.user_id);

  const payloadUsers = {
    model: MODEL.USER,
    fields: [
      "name",
      "last_name",
      "id",
      "role",
      "image_id",
      "device_id",
      "status",
    ],
  };

  let filtersUsers = [
    {
      field: "id",
      op: "in",
      value: mapUserLocations,
    },
    {
      field: "id",
      op: "!=",
      value: user.value.id,
    },
  ];
  if (search) {
    filtersUsers = [
      {
        field: "id",
        op: "in",
        value: mapUserLocations,
      },
      {
        field: "id",
        op: "!=",
        value: user.value.id,
      },
      {
        field: "name",
        op: "like",
        value: search,
      },
    ];
  }

  const users = await handleSearchReadModel(payloadUsers, filtersUsers);

  for (const item of users.items) {
    const { id, image_id } = item;
    if (image_id) {
      const base64 = await getPhoto(id, image_id, true);
      const image = `data:image/png;base64,${base64}`;
      item.image = image;
    }
  }
  return users.items;
};

async function playAudio() {
  const { togglePlay } = useRecord();
  const item = audioFiles[0];
  if (item) {
    const audio = new Audio();
    audio.src = item.audio;
    await audio.play();

    audio.onended = function () {
      if (item.is_more) {
        getNotificationsQueue();
      } else {
        togglePlay(false);
        audio.remove();
      }
    };
    audioFiles.pop();
  }
}

export const playAudioFake = async () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const audioUrl = require('@/assets/audio/wehop_silent.mp3')
  const audio = new Audio();
  audio.src = audioUrl;
  audio.volume = 0.2;
  await audio.play();
  audio.onended = function () {
    audio.remove()
  };
}

/**
 * Deprecated - Use getNotificationsQueue
 */
export const getMessagesPending = async (notifications: any) => {
  const messagesIds = notifications.map((item: any) => item.message_id);
  if (messagesIds.length) {
    const payloadMessage = {
      model: MODEL.MESSAGE,
      fields: ["location_id", "user_id", "id", "file_id", "status"],
    };

    const filtersMessage = [
      {
        field: "id",
        op: "in",
        value: messagesIds,
      },
    ];
    const messages = await handleSearchReadModel(
      payloadMessage,
      filtersMessage
    );
    for (const message of messages.items) {
      const { getAudio } = useAudio();
      try {
        if (message.id && message.file_id) {
          const audio = await getAudio({
            model_id: message.id,
            id: message.file_id,
          });
          audioFiles.push({
            notification_id: notifications.find(
              (item: any) => item.message_id === message.id
            )?.id,
            id: message.file_id,
            audio: `data:audio/wav;base64,${audio}`,
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    }
    if (audioFiles.length) {
      await playAudio();
    }
  }
};

/**
 * Deprecated - Use getNotificationsQueue
 */
export const getNotifications = async () => {
  const { user } = useRegister();

  const payloadNotification = {
    model: MODEL.NOTIFICATION,
    fields: ["message_id", "user_id", "id"],
  };

  const filtersNotifications = [
    {
      field: "user_id",
      op: "==",
      value: user.value.id,
    },
  ];
  const notifications = await handleSearchReadModel(
    payloadNotification,
    filtersNotifications
  );

  await getMessagesPending(notifications.items);
};

export const getNotificationsPendingByIds = async (
  ids: (number | undefined)[]
) => {
  const { user } = useRegister();

  const payloadNotification = {
    model: MODEL.NOTIFICATION,
    fields: ["message_id", "user_id", "id"],
  };

  const filtersNotifications = [
    {
      field: "message_id",
      op: "in",
      value: ids,
    },
    {
      field: "user_id",
      op: "==",
      value: user.value.id,
    },
  ];
  const notifications = await handleSearchReadModel(
    payloadNotification,
    filtersNotifications
  );

  return notifications;
};

export const getNotificationsQueue = async () => {
  const coreStore: CoreStore = useCoreStore();
  const { togglePlay } = useRecord();
  try {
    audioFiles.pop();
    const notification =
      coreStore.notificationMode == NOTIFICATION_TYPE.LOCATION
        ? await handlerNotificationQueue(
            NOTIFICATION_TYPE.LOCATION,
            coreStore.notificationReceiptId
          )
        : await handlerNotificationQueue(
            NOTIFICATION_TYPE.USER,
            coreStore.notificationReceiptId
          );

    if (notification && notification.message_id) {
      audioFiles.push({
        message_id: notification.message_id,
        id: notification.file_id,
        audio: `data:audio/wav;base64,${notification.file}`,
        is_more: notification.is_more,
      });
    } else {
      togglePlay(false);
    }
  } catch (error) {
    togglePlay(false);
    console.log("error", error);
  }
  if (audioFiles.length) {
    togglePlay(true);
    await playAudio();
  }
};

export const handleRemoveNotifications = async (messages: INotification[]) => {
  const { user } = useRegister();

  const payloadMessage = {
    model: MODEL.NOTIFICATION,
    fields: ["message_id"],
  };

  for (const message of messages) {
    const filtersMessage = [
      {
        field: "message_id",
        op: "==",
        value: message.message_id,
      },
      {
        field: "user_id",
        op: "==",
        value: user.value.id,
      },
    ];
    const notifications = await handleSearchReadModel(
      payloadMessage,
      filtersMessage
    );

    if (notifications) {
      await handleDeleteModel({
        model: MODEL.NOTIFICATION,
        id: notifications.items[0].id,
      });
    }
  }
  return true;
};

export const handleRemoveNotification = async (messageId: number) => {
  const { user } = useRegister();

  const payloadMessage = {
    model: MODEL.NOTIFICATION,
    fields: ["message_id"],
  };

  const filtersMessage = [
    {
      field: "message_id",
      op: "==",
      value: messageId,
    },
    {
      field: "user_id",
      op: "==",
      value: user.value.id,
    },
  ];
  const notifications = await handleSearchReadModel(
    payloadMessage,
    filtersMessage
  );

  if (notifications) {
    await handleDeleteModel({
      model: MODEL.NOTIFICATION,
      id: notifications.items[0].id,
    });
  }
};

export const handleSendAudioToMessage = async (payload: any) => {
  return await post({
    url: "message",
    payload: payload,
  });
};
