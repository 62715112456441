export const USER_TYPES = {
  EMPLOYEE: "Member",
  MANAGER: "Manager",
  OWNER: "Owner",
};

export const PLAN_TYPE = {
  FREE: 1,
  PRO: 2,
};

export const PLAN_TYPE_NAME = {
  FREE: "Plan Freemium",
  PRO: "Plan Pro",
};

export const PLAN_TYPE_CODE = {
  FREE: 1001,
  PRO: 1002,
};

export const ROLES = {
  EMPLOYEE: "member",
  OWNER: "owner",
  MANAGER: "manager",
};

export const ROLE_ID = {
  OWNER: 10000,
  MANAGER: 10001,
  EMPLOYEE: 10002,
};

export enum ROLES_ENUM {
  EMPLOYEE = 10002,
  OWNER = 10000,
  MANAGER = 10001,
}

export function getRoleName(roleNumber: number): string {
  switch (roleNumber) {
    case ROLES_ENUM.EMPLOYEE:
      return "member";
    case ROLES_ENUM.OWNER:
      return "owner";
    case ROLES_ENUM.MANAGER:
      return "manager";
    default:
      return "unknown";
  }
}


export const SMS_TYPE = {
  FORGOT_PASSWORD: "recover_password",
  SIGNUP: "signup",
};

export const MODEL = {
  SUBSCRIPTION_PLAN: "subscriptionplan",
  SUBSCRIPTION: "subscription",
  MESSAGE: "message",
  LOCATION: "location",
  APPLICATION: "application",
  USER: "user",
  USERLOCATION: "userlocation",
  NOTIFICATION: "notification",
  CITY: "city",
  COUNTRY: "country",
  TASK: "task",
  COMMENT: "comment",
  PAYMENT_CHECK: "payment/check",
  ADVICE: "advice",
  ENTITY: "entity",
};

export const APPLICATION_STATUS = {
  ACCEPTED: "accepted",
  REJECTED: "rejected",
  WAITING: "waiting",
};

export const PROFILE_VIEW = {
  MY_PROFILE: "my_profile",
  MY_BUSINESS: "my_business",
};

export const GROUP_OWNER_VIEW = {
  MEMBERS: "members",
  REQUESTS: "requests",
};

export const TASKS_VIEW = {
  PENDING: "pending",
  FINISHED: "finished",
};

export const ENUM_TASK = {
  pending: "to_do",
  progress: "in_progress",
  finished: "finished",
};

export const NOTIFICATION_TYPE = {
  LOCATION: "Location",
  USER: "User",
};

export const ERROR_HANDLER_MESSAGE = {
  SignatureHasExpired: "Signature has expired",
  TokenNotValid: "Token not valid",
  NotMoreLocations: "No valid fields in body: The subscription don't support more locations",
};

export const SUBSCRIPTION_STATUS = {
  SubscriptionStateActive: "SUBSCRIPTION STATE ACTIVE",
  SubscriptionStateActiveUnderscore: "SUBSCRIPTION_STATE_ACTIVE",
};

export const TOUR = {
  NONE: "none",
  LOCATION: "location",
  TASK: "task",
  TEAM: "team",
  TOUR04: "tour04",
  TOUR05: "tour05",
  TOUR06: "tour06",
  TOUR07: "tour07",
  TOUR08: "tour08",
  TOUR09: "tour09",
  TOUR10: "tour10",
};
