import {GenericStore, useGenericStore} from "@/stores/generic";
import {storeToRefs} from "pinia";

export function useGeneric() {
    const genericStore: GenericStore = useGenericStore();
    const {refresh} = storeToRefs(genericStore);

    return {
        refresh,
        setRefresh: genericStore.setRefresh
    };
}
