import { MODEL } from "@/utils/constants";
import { handleSearchReadModel } from "@/services/generic";
import { useRegister } from "@/composables/useRegister";
import { IUserLocation } from "@/interfaces/user_location/IUserLocation";
import { handleLocationData } from "@/services/location";
import { ILocation } from "@/interfaces/locale/ILocation";
import { ILocationResult } from "@/interfaces/ILocationResult";

export const handleUserLocationData = async (): Promise<IUserLocation[]> => {
  const { user } = useRegister();

  try {
    const payload = {
      model: MODEL.USERLOCATION,
      fields: ["id", "user_id", "location_id", "role_id"],
    };
    const filter = [
      {
        field: "location_id",
        op: "==",
        value: user.value.location_id,
      },
    ];
    const data = await handleSearchReadModel(payload, filter);
    return data.items as IUserLocation[];
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const handleUsersByLocationData = async (): Promise<
  ILocationResult | undefined
> => {
  try {
    const locations = await handleLocationData();
    const locationIdFiltered = locations.map(
      (location: ILocation) => location.id
    );

    const payload = {
      model: MODEL.USERLOCATION,
      fields: ["id", "user_id", "location_id", "role_id"],
    };
    const filter = [
      {
        field: "location_id",
        op: "in",
        value: locationIdFiltered,
      },
    ];
    const data = await handleSearchReadModel(payload, filter);
    const users: IUserLocation[] = data.items as IUserLocation[];
    const usersUnique: IUserLocation[] = Array.from(
      new Map(users.map((item) => [item.user_id, item])).values()
    );

    return {
      users: usersUnique,
      locations: locations,
    };
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const handleLocationByUserData = async (): Promise<ILocation[]|undefined> => {
  const { user } = useRegister();

  try {
    ///Get Location By User ID
    const payload = {
      model: MODEL.USERLOCATION,
      fields: ["id", "user_id", "location_id", "role_id"],
    };
    const filter = [
      {
        field: "user_id",
        op: "==",
        value: user.value.id,
      },
    ];

    const data = await handleSearchReadModel(payload, filter);
    const userLocation = data as IUserLocation[];
    const locationIdFiltered = userLocation.map(
      (userLocation: IUserLocation) => userLocation.location_id
    );

    ///Get Locations
    const payloadLocation = {
      model: MODEL.LOCATION,
      fields: ["id", "user_id", "location_id", "role_id"],
    };
    const filterLocation = [
      {
        field: "id",
        op: "in",
        value: locationIdFiltered,
      },
    ];

    const locationResult = await handleSearchReadModel(
      payloadLocation,
      filterLocation
    );
    const location = locationResult as ILocation[];

    return location;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};
