import { createI18n } from "vue-i18n";
import EN from "@/locales/en";
import ES from "@/locales/es";

const i18n = createI18n({
  locale: "es",
  fallbackLocale: "es",
  messages: {
    en: EN,
    es: ES,
  },
});

export default i18n;
