import { get } from "@/services/api";
import {handlerGetPhoto} from "@/services/register";

export const handleMe = async () => {
  return await get({
    url: "me",
    payload: {},
  });
};

export const getPhotoUrl = async (userId: number, imageId: number, url: boolean) => {
  return handlerGetPhoto(userId, imageId, url);
};
