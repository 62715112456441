import i18n from "@/plugins/i18n";

export default [
  {
    label: i18n.global.t("global.home"),
    icon: "Home",
    to: "admin.home",
  },
  {
    label: i18n.global.t("global.talkie"),
    icon: "VoiceCricle",
    to: "default.talkie.list",
  },
  {
    label: i18n.global.t("global.locals"),
    icon: "Shop",
    to: "admin.locations.list",
  },
  {
    label: i18n.global.t("global.tasks"),
    icon: "ClipboardText",
    to: "default.tasks.list",
  },
  {
    label: i18n.global.t("global.members"),
    icon: "People",
    to: "admin.groups.list",
  },
];
