import { defineStore } from "pinia";
import { ILoginResponse, IUser } from "@/interfaces/login/ILoginResponse";
import { ILocation } from "@/interfaces/locale/ILocation";
import { INotificationNumber } from "@/interfaces/register/IRegister";

export interface RegisterState {
  user: IUser;
  access_token: string;
  location: ILocation;
  firstName: string;
  lastName: string;
  phone: string;
  phonePrefix: string;
  password: string;
  email: string;
  birthday: string;
  pin: string;
  entity_name: string;
  commercial_name: string;
  cif: string;
  number_employees: number;
  number_locals: number;
  vat: string;
  address: string;
  city_id: string;
  country_id: string;
  userPhoto: string;
  userSelectedPhoto: string;
  localePhoto: string;
  notifications: INotificationNumber[];
}

export interface RegisterActions {
  setUser: (this: any, payload: ILoginResponse) => void;
  setUserInfo: (this: any, payload: any) => void;
  setLocation: (this: any, payload: ILocation) => void;
  setUserOwnerInfo: (this: any, payload: any) => void;
  setPassword: (this: any, payload: any) => void;
  setPin: (this: any, payload: any) => void;
  setEntityInfo: (this: any, payload: any) => void;
  clearEntityStore: (this: any, payload: any) => void;
  clearUserStore: (this: any) => void;
  setUserPhoto: (this: any, payload: string) => void;
  setUserSelectedPhoto: (this: any, payload: string) => void;
  setLocalePhoto: (this: any, payload: string) => void;
  setStoreNotifications: (this: any, payload: INotificationNumber[]) => void;
}

const getUserInfoLocalStorage = () => {
  const info = localStorage.getItem("user");
  if (info) {
    return JSON.parse(info);
  } else {
    return {};
  }
};

const getAccessTokenLocalStorage = () => {
  const accessToken = localStorage.getItem("access_token");
  if (accessToken) {
    return accessToken; //JSON.parse(accessToken);
  } else {
    return "";
  }
};

const getLocationStorage = (): ILocation => {
  try {
    const locationString = localStorage.getItem("location");
    if (locationString) {
      const location: ILocation = JSON.parse(locationString) as ILocation;
      return location;
    }
    return <ILocation>{
      name: "",
      address: "",
      image_id: 0,
      country_id: 0,
      phone_number: "",
      email: "",
      local_type: "",
    };
  } catch (e) {
    return <ILocation>{
      name: "",
      address: "",
      image_id: 0,
      country_id: 0,
      phone_number: "",
      email: "",
      local_type: "",
    };
  }
};

export const useRegisterStore = defineStore({
  id: "register",
  state: (): RegisterState => ({
    user: getUserInfoLocalStorage(),
    access_token: getAccessTokenLocalStorage(),
    location: getLocationStorage(),
    firstName: "",
    lastName: "",
    phone: "",
    phonePrefix: "",
    password: "",
    email: "",
    birthday: "",
    pin: "",
    entity_name: "",
    commercial_name: "",
    cif: "",
    vat: "",
    number_employees: 0,
    number_locals: 0,
    address: "",
    city_id: "",
    country_id: "",
    userPhoto: "",
    userSelectedPhoto: "",
    localePhoto: "",
    notifications: [],
  }),
  actions: {
    setUser(this: any, payload: ILoginResponse) {
      if (payload.access_token) {
        localStorage.setItem("access_token", payload.access_token);
        this.access_token = payload.access_token;
      }
      this.user = payload.user;
      localStorage.setItem("user", JSON.stringify(payload.user));
    },
    setLocation(this: any, payload: ILocation) {
      this.location = payload;
      localStorage.setItem("location", JSON.stringify(payload));
    },
    setUserInfo(this: any, payload: any) {
      this.firstName = payload.firstName;
      this.lastName = payload.lastName;
      this.phone = payload.phone;
      this.phonePrefix = payload.phonePrefix;
      this.password = payload.password;
    },
    setUserOwnerInfo(this: any, payload: any) {
      this.firstName = payload.firstName;
      this.lastName = payload.lastName;
      this.phone = payload.phone;
      this.email = payload.email;
      this.birthday = payload.birthday;
      this.phonePrefix = payload.phonePrefix;
    },
    setPassword(this: any, payload: any) {
      this.password = payload.password;
    },
    setPin(this: any, payload: any) {
      this.pin = payload.pin;
    },
    setEntityInfo(this: any, payload: any) {
      this.entity_name = payload.name;
      this.commercial_name = payload.commercial_name;
      this.address = payload.address;
      this.cif = payload.cif;
      this.vat = payload.vat;
      this.country_id = payload.country_id;
      this.city_id = payload.city_id;
    },
    clearEntityStore(this: any) {
      this.entity_name = "";
      this.commercial_name = "";
      this.address = "";
      this.cif = "";
      this.vat = "";
      this.country_id = "";
      this.city_id = "";
      this.password = "";
    },
    clearUserStore(this: any) {
      localStorage.clear();
      this.user = getUserInfoLocalStorage();
    },
    setStoreNotifications(this: any, payload: INotificationNumber[]) {
      this.notifications = payload;
    },
    setUserPhoto(this: any, payload: string) {
      this.userPhoto = payload;
    },
    setUserSelectedPhoto(this: any, payload: string) {
      this.userSelectedPhoto = payload;
    },
    setLocalePhoto(this: any, payload: string) {
      this.localePhoto = payload;
    },
  } as RegisterActions,
});

export type RegisterStore = ReturnType<typeof useRegisterStore>;
