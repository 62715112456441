import {toRefs} from "vue";
import {PushNotifications} from "@capacitor/push-notifications";
import {LocalNotifications, ActionPerformed} from "@capacitor/local-notifications"
import {useNativePlatform} from "@/composables/useNativePlatform";
import {useRouter} from "vue-router";
import {toastController} from "@ionic/vue";
import {CoreStore, useCoreStore} from "@/stores/core";
import {IPushNotification} from "@/interfaces/notification/IPushNotification";
import i18n from "@/plugins/i18n";
import { GROUP_OWNER_VIEW } from "@/utils/constants";
import useCore from "@/composables/useCore";
import { Capacitor } from "@capacitor/core";


export function useTokenNotification() {
    const coreStore: CoreStore = useCoreStore();
    const { setGroupTabSelected, setRefreshDashboard } = useCore();
    const {isMobile} = useNativePlatform();

    const initPushNotifications = async () => {

        const router = useRouter();

      if (Capacitor.isNativePlatform()) {

        const addListeners = async () => {
          await PushNotifications.addListener("registration", (token) => {
            console.info("Registration token: ", token.value);
            coreStore.setTokenId(token.value);
          });

          await PushNotifications.addListener("registrationError", (err) => {
            console.error("Registration error: ", err.error);
          });

          await PushNotifications.addListener(
            "pushNotificationReceived",
            async (notification) => {
              let message = "";
              const notificationData = notification.data as IPushNotification;
              if (notificationData.type === "talkie") {
                message = i18n.global.t("notification.talkieMessage");
              }
              if (notificationData.type === "task") {
                message = i18n.global.t("notification.newTaskAssignedMessage");
                coreStore.toggleTask({ status: true, id: notificationData.id ?? -1 },);
              }
              if (notificationData.type === "location") {
                setRefreshDashboard(true);
              }
              const toast = await toastController.create({
                message,
                duration: 1500,
                position: "bottom",
                mode: "ios",
              });
              await toast.present();
              await LocalNotifications.schedule({
                notifications: [
                  {
                    id: 1,
                    title: notification.title as string,
                    body: notification.body as string,
                    schedule: { at: new Date(Date.now() + 100) },
                    extra: notificationData,
                  },
                ],
              });

              console.log("Push notification received: ", JSON.stringify(notification.data));
            }
          );

          await PushNotifications.addListener(
            "pushNotificationActionPerformed",
            async (notification) => {
              const notificationData = notification.notification.data as IPushNotification;
              if (notificationData.type === "talkie") {
                await router.push({ name: 'default.talkie.list' });
              }
              if (notificationData.type === "task") {
                coreStore.toggleTask({ status: true, id: notificationData.id ?? -1 },);
                await router.push({ name: 'default.tasks.list' });
              }
              if (notificationData.type === "location") {
                setGroupTabSelected(GROUP_OWNER_VIEW.REQUESTS);
                await router.push({ name: 'admin.groups.list' });
              }
            }
          );

          await LocalNotifications.addListener(
            "localNotificationActionPerformed",
            async (notification: ActionPerformed) => {
              const notificationData = notification.notification.extra as IPushNotification;
              if (notificationData.type === "talkie") {
                await router.push({ name: 'default.talkie.list' });
              }
              if (notificationData.type === "task") {
                coreStore.toggleTask({
                  status: true,
                  id: notification.notification.id ?? -1,
                });
                await router.push({ name: 'default.tasks.list' });
              }
              if (notificationData.type === "location") {
                setGroupTabSelected(GROUP_OWNER_VIEW.REQUESTS);
                await router.push({ name: 'admin.groups.list' });
              }
            },
          );

        };

        const registerNotifications = async () => {
          let permStatus = await PushNotifications.checkPermissions();

          if (permStatus.receive === "prompt") {
            permStatus = await PushNotifications.requestPermissions();
          }

          if (permStatus.receive !== "granted") {
            throw new Error("User denied permissions!");
          }

          await PushNotifications.register();
        };

        const getDeliveredNotifications = async () => {
          const notificationList =
            await PushNotifications.getDeliveredNotifications();
        };

        if (isMobile) {
          await addListeners();
          await registerNotifications();
          await getDeliveredNotifications();
        }
      }
    };

    return {
        ...toRefs(coreStore),
        setTokenId: coreStore.setTokenId,
        initPushNotifications,
    };
}
