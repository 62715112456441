export const code = `<template>
  <ion-page class="ion-padding">
    <ion-content>
      <ion-card>
        <ion-card-content>
          <FormComponent
            :schema="schema"
            :model="model"
            @success="handleSuccess"
          />
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>
<script lang="ts" setup>
import {
  IonPage,
  IonContent,
  IonCard,
  IonCardContent,
} from "@ionic/vue";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import { ref } from "vue";
import type { Ref } from "vue";
import { mapModel, mapSchema } from "@/utils/getMapModel";

const handleSuccess = (values: any) => {
  console.log("values:", values);
};

const model: Ref<any> = ref({
  name: "dummy",
  fields: [],
});

const getModel = async () => {
  const describe = await handleGetDescribeModel(model.value.name);
  const modelMap = mapModel(describe);
  const schemaMap = mapSchema(describe);
  schema.value = schemaMap;
  model.value.fields = modelMap;
};
getModel();
</script>
`;
