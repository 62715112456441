import {get} from "@/services/api";
import {useRegister} from "@/composables/useRegister";
import {NOTIFICATION_TYPE} from "@/utils/constants";

export const handlerNotificationQueue = async (type: string, senderId: number) => {

    const { user } = useRegister();

    let url = '/notification/queue';

    if (type == NOTIFICATION_TYPE.LOCATION) {
        url += `?location_id=${senderId}`;
    }

    if (type == NOTIFICATION_TYPE.USER) {
        url += `?user_id=${user.value.id}&created_user_id=${senderId}`;
    }
    return await get({
        url: url,
        payload: {},
    });
};


export const handlerMessageSummary = async (type: string, senderId: number, page: number) => {

    const { user } = useRegister();

    let url = '/message/chat';

    if (type == NOTIFICATION_TYPE.LOCATION) {
        url += `?location_id=${user.value.location_id}&page=${page}`;
    }
    
    if (type == NOTIFICATION_TYPE.USER) {
        url += `?location_id=${user.value.location_id}&user_id=${senderId}&page=${page}`;
    }
    return await get({
        url: url,
        payload: {},
    });
};

