import { reactive, toRefs } from "vue";
import { ILogin } from "@/interfaces/login/ILogin";
import { handleLogin, handleLogout } from "@/services/auth";
import router from "@/router";
import { ILoginResponse } from "@/interfaces/login/ILoginResponse";
import { useRegister } from "@/composables/useRegister";
import { MODEL, ROLES } from "@/utils/constants";
import { useLocation } from "@/composables/useLocation";
import { useTokenNotification } from "@/composables/useTokenNotification";
import { handleSearchReadModel } from "@/services/generic";
import { CoreStore, useCoreStore } from "@/stores/core";
import { getTimeZone } from "@/utils";
import useCore from "@/composables/useCore";
import {
  getSubscriptionAvailability,
  getTeamMembers,
} from "@/services/subscription";

export function useAuth() {
  const { setUser, clearUserStore, setNotifications } = useRegister();
  const { getLocation } = useLocation();
  const { setLocation } = useRegister();
  const { tokenId } = useTokenNotification();
  const coreStore: CoreStore = useCoreStore();
  const { setResetPinDigit, setResetForm } = useCore();
  const state = reactive<ILogin>({
    phone: undefined,
    password: "",
    loginSuccess: false,
    data: {},
  });
  const login = async () => {
    setResetPinDigit(true);
    try {
      const data: ILoginResponse = await handleLogin({
        username: state.phone,
        password: state.password,
        device_id: tokenId.value,
        user_gtm: getTimeZone(),
      });

      if (data.access_token) {
        coreStore.setPhoneSaved(state.phone!.toString());
        state.loginSuccess = true;
        state.data = data;
        localStorage.setItem("is_logged", "true");
        setUser(data);

        let location = null;
        if (data.user.location_id != null) {
          location = await getLocation(data.user.location_id ?? -1);

          if (location) {
            setLocation(location[0]);
          }
        }

        await setNotifications();

        if (
          data.user.role == ROLES.EMPLOYEE ||
          data.user.role == ROLES.MANAGER
        ) {
          if (location != null) {
            await router.push({ name: "employee.home" });
          } else {
            const payload = {
              model: MODEL.APPLICATION,
              fields: ["id", "location_code", "user_id"],
            };
            const filter = [
              {
                field: "status",
                op: "in",
                value: ["waiting"],
              },
              {
                field: "user_id",
                op: "==",
                value: data.user.id,
              },
            ];
            handleSearchReadModel(payload, filter).then(async (data) => {
              if (data.items.length > 0) {
                await router.push({ name: "joinRequestInitialList" });
              } else {
                await router.push({ name: "joinHome" });
              }
            });
          }
        } else {
          if (data.user.location_id) {
            //Get Team Members (Only for Owners)
            await getTeamMembers(data.user.location_id);
            //Get Subscription Availability (Only for Owners)
            await getSubscriptionAvailability();
          }
          await router.push({ name: "admin.home" });
        }
      } else {
        state.loginSuccess = false;
      }
      state.loginSuccess = true;
    } catch (e) {
      console.error(e);
    }
  };
  const logout = async () => {
    try {
      await handleLogout().then(async () => {
        setResetPinDigit(true);
        setResetForm(true);
        clearUserStore();
        setTimeout(async () => {
          await router.push({ name: "login" });
        }, 500);
      });
    } catch (e) {
      console.error(e);
    }
  };

  return {
    ...toRefs(state),
    login,
    logout,
  };
}
