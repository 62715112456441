import { IPlanProduct } from "@/interfaces/plan_product/IPlanProduct";
import { defineStore } from "pinia";
import "cordova-plugin-purchase";

export interface PaymentState {
    planProduct?: IPlanProduct,
    transaction?: CdvPurchase.Transaction   
}

export const usePaymentStore = defineStore({
    id: "payment",
    state: (): PaymentState => ({
    }),
    actions: {
        setPlanProduct(planProduct: IPlanProduct) {
            this.planProduct = planProduct
        },
        setTransaction(transaction: any) {
            this.transaction = transaction;
        }
    }
})

export type PaymentStore = ReturnType<typeof usePaymentStore>