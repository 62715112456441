import { storeToRefs } from "pinia";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { computed } from "vue";
export function useRole() {
  const registerStore: RegisterStore = useRegisterStore();
  const { user } = storeToRefs(registerStore);

  const canOwnerAdmin = computed(() => {
    return user.value.role === "superadmin" || user.value.role === "owner";
  });

  return {
    canOwnerAdmin,
  };
}
