import { reactive, toRefs } from "vue";
import { NOTIFICATION_TYPE } from "@/utils/constants";
import { getNotificationsPendingByIds } from "@/services/talkie";
import { INotification } from "@/interfaces/notification/INotification";
import { handlerMessageSummary } from "@/services/message";
import { IMessageSummary } from "@/interfaces/IMessageSummary";

export function useMessage() {
  const state = reactive({
    messages: <IMessageSummary[]>[],
    total: 0,
    size: 0,
    page: 1,
    grouped: {
      today: <IMessageSummary[]>[],
      yesterday: <IMessageSummary[]>[],
      prevDate: <IMessageSummary[]>[],
    },
    notifications: <INotification[]>[],
  });
  const getLocationMessages = async (locationId: number) => {
    try {
      const data = await handlerMessageSummary(
        NOTIFICATION_TYPE.LOCATION,
        -1,
        state.page
      );
      state.messages = data.items as IMessageSummary[];
      state.total = data.total;
      state.size = data.size;

      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const prevDate = new Date();
      prevDate.setDate(prevDate.getDate() - 2);

      state.grouped.today = [
        ...state.grouped.today,
        ...state.messages.filter(
          (item) =>
            new Date(item.created_datetime).toLocaleDateString("es-ES") ===
            today.toLocaleDateString("es-ES")
        ),
      ];

      state.grouped.yesterday = [
        ...state.grouped.yesterday,
        ...state.messages.filter(
          (item) =>
            new Date(item.created_datetime).toLocaleDateString("es-ES") ===
            yesterday.toLocaleDateString("es-ES")
        ),
      ];
      state.grouped.prevDate = [
        ...state.grouped.prevDate,
        ...state.messages.filter(
          (item) =>
            new Date(item.created_datetime).toLocaleDateString("es-ES") ===
            prevDate.toLocaleDateString("es-ES")
        ),
      ];

      const messageIds: (number | undefined)[] = state.messages.map(
        (item: IMessageSummary) => item.id
      );

      if (messageIds.length > 0) {
        const notifications = await getNotificationsPendingByIds(messageIds);
        state.notifications = notifications.items as INotification[];
      }

      return state.grouped;
    } catch (err) {
      console.error(err);
    }
  };

  const incrementPage = () => {
    state.page = state.page + 1;
  };

  const resetPage = () => {
    state.page = 1;
    state.messages = [];
    state.grouped.today = [];
    state.grouped.yesterday = [];
    state.grouped.prevDate = [];
  };

  const getLocationMessagesByUser = async (
    locationId?: number,
    userId?: string
  ) => {
    try {
      const data = await handlerMessageSummary(
        NOTIFICATION_TYPE.USER,
        parseInt(userId!),
        state.page
      );
      state.messages = data.items as IMessageSummary[];
      state.total = data.total;
      state.size = data.size;

      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const prevDate = new Date();
      prevDate.setDate(prevDate.getDate() - 2);

      state.grouped.today = [
        ...state.grouped.today,
        ...state.messages.filter(
          (item) =>
            new Date(item.created_datetime).toLocaleDateString("es-ES") ===
            today.toLocaleDateString("es-ES")
        ),
      ];
      state.grouped.yesterday = [
        ...state.grouped.yesterday,
        ...state.messages.filter(
          (item) =>
            new Date(item.created_datetime).toLocaleDateString("es-ES") ===
            yesterday.toLocaleDateString("es-ES")
        ),
      ];
      state.grouped.prevDate = [
        ...state.grouped.prevDate,
        ...state.messages.filter(
          (item) =>
            new Date(item.created_datetime).toLocaleDateString("es-ES") ===
            prevDate.toLocaleDateString("es-ES")
        ),
      ];

      const messageIds: (number | undefined)[] = state.messages.map(
        (item: IMessageSummary) => item.id
      );

      if (messageIds.length > 0) {
        const notifications = await getNotificationsPendingByIds(messageIds);
        state.notifications = notifications.items as INotification[];
      }

      return state.grouped;
    } catch (err) {
      console.error(err);
    }
  };

  return {
    ...toRefs(state),
    incrementPage,
    resetPage,
    getLocationMessages,
    getLocationMessagesByUser,
  };
}
