export const getFieldValue = (props: any, item: any, field: any) => {
  const selectRelated = props.model.fields.find(
    (item: any) => item.depends_on === field.model
  );
  if (selectRelated) {
    return [
      {
        model: selectRelated.model,
        field: field.field_search,
        op: "==",
        value: item.value,
      },
    ];
  } else {
    return [];
  }
};

export const getFilters = (filters: any[], field: any) => {
  const selectRelated = filters.find(
    (item: any) =>
      item.field === field.foreign_key && item.model === field.model
  );
  if (selectRelated) {
    const filter = JSON.parse(JSON.stringify(filters));
    return filter.map((item: any) => {
      delete item.model;
      return item;
    });
  }
  return [];
};

export const setDefaultValues = (fields: any) => {
  interface Key {
    [name: string]: any;
  }

  const obj: Key = {};
  fields.forEach((field: any) => {
    if (field.default) {
      obj[field.name] = field.default;
    }
  });
  return obj;
};
