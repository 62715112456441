import {reactive, toRefs} from "vue";
import router from "@/router";
import {APPLICATION_STATUS, MODEL, ROLE_ID, ROLES} from "@/utils/constants";
import {handlePostModel, handlePutModel, handleSearchReadModel,} from "@/services/generic";
import {ILocation} from "@/interfaces/locale/ILocation";
import {useRegister} from "@/composables/useRegister";
import {RegisterStore, useRegisterStore} from "@/stores/register";
import {toastController} from "@ionic/vue";
import i18n from "@/plugins/i18n";
import {useLocation} from "@/composables/useLocation";
import {IUserLocation} from "@/interfaces/user_location/IUserLocation";

export function useJoin() {
  const { setLocation } = useRegister();
  const { getLocation } = useLocation();
  const registerStore: RegisterStore = useRegisterStore();
  const {user} = useRegister();

  const state = reactive({
    code: "",
    infoSent: false,
    rejectSent: false,
    confirmSent: false,
    location: [] as ILocation[],
  });
  const sendCode = async () => {
    try {
      const payload = {
        model: MODEL.LOCATION,
        fields: [
          "id",
          "name",
          "address",
          "country_id",
          "phone_number",
          "email",
          "local_type",
          "code",
          "entity_id",
          "image_id"
        ],
      };
      const filter = [
        {
          field: "code",
          op: "==",
          value: state.code,
        },
      ];
      const data = await handleSearchReadModel(payload, filter);
      state.location = data.items as ILocation[];
      state.infoSent = true;
      if (data.total != 0) {
        setLocation(data.items[0]);
        await router.push({ name: "joinTeamPreview" });
      }
    } catch (err) {
      console.error(err);
      state.infoSent = false;
    }
  };

  const sendApplication = async () => {
    try {
      const payload = {
        model: MODEL.APPLICATION,
        fields: {
          location_code: registerStore.location.code,
          user_id: registerStore.user.id,
          status: APPLICATION_STATUS.WAITING,
        },
      };
      handlePostModel(payload).then(async (_) => {

        const payload = {
          model: MODEL.USERLOCATION,
          fields: [
            "id",
            "location_id",
            "user_id",
          ],
        };
        const filter = [
          {
            field: "user_id",
            op: "==",
            value: user.value.id,
          },
        ];
        await handleSearchReadModel(payload, filter).then(async (data) => {
          if (data.items.length > 0) {
            const userLocation = data.items[0] as IUserLocation;
            const locations = await getLocation(userLocation.location_id);
            const location = locations ? locations[0] : [];
            setLocation(location as ILocation);
            await router.push({name: "employee.home"});
          } else {
            await router.push({name: "joinRequestSent"});
          }

        })
      });
    } catch (err) {
      console.error(err);
    }
  };

  const rejectRequest = async (applicationId: number) => {
    try {
      handlePutModel({
        model: MODEL.APPLICATION,
        id: applicationId,
        fields: {
          status: APPLICATION_STATUS.REJECTED,
        },
      }).then(async (_) => {
        state.rejectSent = true;
        const toast = await toastController.create({
          message: i18n.global.t("global.updateSuccess"),
          duration: 1500,
          position: "bottom",
          mode: "ios",
          icon: "tick-circle",
        });
        await toast.present();
      });
    } catch (e) {
      state.rejectSent = false;
      console.error(e);
    }
  };

  const confirmRequest = async (applicationId: number, role: string) => {
    try {
      await handlePutModel({
        model: MODEL.APPLICATION,
        id: applicationId,
        fields: {
          status: APPLICATION_STATUS.ACCEPTED,
          role_id: role == ROLES.MANAGER ? ROLE_ID.MANAGER : ROLE_ID.EMPLOYEE,
        },
      }).then(async () => {
        state.confirmSent = true;
        const toast = await toastController.create({
          message: i18n.global.t("global.updateSuccess"),
          duration: 1500,
          position: "bottom",
          mode: "ios",
        });
        await toast.present();
      });
    } catch (e) {
      state.confirmSent = false;
      console.error(e);
    }
  };

  return {
    ...toRefs(state),
    sendCode,
    sendApplication,
    rejectRequest,
    confirmRequest,
  };
}
