import { get, post, put, patch, remove } from "@/services/api";
import { getQueryParamsString } from "@/utils/getQueryParams";
export const handleGetDescribeModel = async (model: string, fields: string) => {
  return await get({
    url: `/model/${model}/describe?${fields}`,
    errMessage: `Ocurrio un error al obtener el model: ${model}`,
  });
};

export const handlePostModel = async (payload: any) => {
  return await post({
    url: `/model/${payload.model}/create`,
    payload: payload.fields,
  });
};

export const handlePutModel = async (payload: any) => {
  return await patch({
    url: `/model/${payload.model}/update?id=${payload.id}`,
    payload: payload.fields,
  });
};

export const handleReadModel = async (payload: any) => {
  return await get({
    url: `/model/${payload.model}/read?id=${payload.id}`,
  });
};

export const handleSearchModel = async (payload: any) => {
  return await put({
    url: `/model/${payload.model}/search`,
    payload: payload.fields,
  });
};

export const handleDeleteModel = async (payload: any) => {
  return await remove({
    url: `/model/${payload.model}/delete?id=${payload.id}`,
  });
};

export const handleSearchReadModel = async (
  payload: any,
  filters: any = [],
  sort_by: any = []
) => {
  const size = payload.size || 10;
  const page = payload.page || 1;
  const queryFields = getQueryParamsString(payload.fields);
  return await put({
    url: `/model/${payload.model}/search_read${queryFields}&size=${size}&page=${page}`,
    payload: { filters, sort_by },
  });
};

export const handlerUploadFile = async (payload: any, file: string) => {
  return await post({
    url: `/${payload.model}/${payload.model_id}/filebase/${payload.field}`,
    payload: { file },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const handlerGetFile = async (payload: any) => {
  return await get({
    url: `/${payload.model}/${payload.model_id}/file/${payload.field}`,
  });
};

export const handlerDeleteFile = async (payload: any) => {
  return await remove({
    url: `/${payload.model}/${payload.model_id}/file/${payload.file_id}`,
  });
};
