import { MODEL } from "@/utils/constants";
import { handleSearchReadModel } from "@/services/generic";
import { ISubscriptionPlan } from "@/interfaces/subscription_plan/ISubscriptionPlan";

export const handleSubscriptionPlanData = async (
  subscriptionPlanId: number
): Promise<ISubscriptionPlan[]> => {
  try {
    const payload = {
      model: MODEL.SUBSCRIPTION_PLAN,
      fields: ["id", "location_max", "user_max"],
    };
    const filter = [
      {
        field: "id",
        op: "==",
        value: subscriptionPlanId,
      },
    ];
    const data = await handleSearchReadModel(
      payload,
      filter
    );
    return data.items;
  } catch (err) {
    console.error(err);
    return [];
  }
};
