import { ref, computed } from "vue";
export default function useCountdown() {
  const timeLeft = ref(0);
  let INTERVAL_ID: any = null;

  function startCountdown(minutes: number) {
    timeLeft.value = 0;
    timeLeft.value = minutes * 60;
    const intervalId = setInterval(() => {
      timeLeft.value--;
      if (timeLeft.value === 0) {
        clearInterval(intervalId);
      }
    }, 1000);
  }

  function startTimer() {
    timeLeft.value = 0;
    if (INTERVAL_ID) {
      clearInterval(INTERVAL_ID);
    }
    INTERVAL_ID = setInterval(() => {
      timeLeft.value++;
    }, 1000);
  }

  const seconds = computed(() => {
    return timeLeft.value % 60;
  });

  const minutes = computed(() => {
    return Math.floor(timeLeft.value / 60);
  });
  return {
    timeLeft,
    startCountdown,
    startTimer,
    minutes,
    seconds,
  };
}
