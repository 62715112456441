import { reactive, toRefs } from "vue";
import {
  INotificationNumber,
  IRegister,
} from "@/interfaces/register/IRegister";
import { storeToRefs } from "pinia";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import router from "@/router";
import { CoreStore, useCoreStore } from "@/stores/core";
import { MODEL, ROLES, SMS_TYPE } from "@/utils/constants";
import { handleSendPhone } from "@/services/forgot_password";
import { ISMSResponse } from "@/interfaces/forgot_password/ISMSResponse";
import {
  handlerGetLocalePhoto,
  handlerGetPhoto,
  handlerSendInvitation,
  handlerUploadLocalePhoto,
  handlerUploadPhoto,
  handleSendUserInfo,
} from "@/services/register";
import { handlePostModel, handlePutModel } from "@/services/generic";
import { ILoginResponse, IUser } from "@/interfaces/login/ILoginResponse";
import { handleMe } from "@/services/me";
import { toastController } from "@ionic/vue";
import i18n from "@/plugins/i18n";
import { handlerNotification } from "@/services/notification";
import { useLocation } from "@/composables/useLocation";
import { IEntityResponse } from "@/interfaces/entity/IEntityResponse";
import { useAudio } from "@/composables/useAudio";
import { useTokenNotification } from "@/composables/useTokenNotification";
import moment from "moment/moment";
import { ILocation } from "@/interfaces/locale/ILocation";
import { getTimeZone } from "@/utils";

export function useRegister() {
  const registerStore: RegisterStore = useRegisterStore();
  const coreStore: CoreStore = useCoreStore();
  const { user, notifications, userPhoto, userSelectedPhoto, localePhoto } =
    storeToRefs(registerStore);
  const { getLocation } = useLocation();
  const { tokenId } = useTokenNotification();
  const state = reactive<IRegister>({
    pin: undefined,
    id: undefined,
    password: "",
    confirmPassword: "",
    phonePrefix: undefined,
    phone: undefined,
    name: undefined,
    last_name: undefined,
    photo: "",
    email: "",
    birthday: "",
    infoSent: false,
    pinSent: false,
    pinCodeValid: false,
    photoSent: false,
    subjectSMS: SMS_TYPE.SIGNUP,
    commercial_name: "",
    address: "",
    cif: "",
    vat: "",
    city_id: "",
    number_employees: 0,
    number_locals: 0,
    country_id: "",
    infoEntitySaved: false,
    isGoBack: false,
  });
  const saveInfo = async () => {
    try {
      registerStore.setUserInfo({
        firstName: state.name,
        lastName: state.last_name,
        password: state.password,
        phone: state.phone,
        phonePrefix: state.phonePrefix,
        user_gtm: getTimeZone(),
      });

      handleSendPhone({
        prefix_number: state.phonePrefix,
        phone_number: state.phone,
        subject: state.subjectSMS,
      }).then(async (data: ISMSResponse) => {
        if (data.detail == "SMS Sended") {
          state.pinSent = true;
          coreStore.nextHelpStep(3);
          await router.push({ name: "registerPinCode" });
        } else {
          state.pinSent = false;
        }
      });
      state.pinSent = true;
    } catch (e) {
      console.error(e);
    }
  };

  const saveOwnerInfo = async () => {
    return new Promise((resolve, reject) => {
      try {
        registerStore.setUserOwnerInfo({
          firstName: state.name,
          lastName: state.last_name,
          phone: state.phone,
          email: state.email,
          birthday: state.birthday,
          phonePrefix: state.phonePrefix,
        });

        handleSendPhone({
          prefix_number: state.phonePrefix,
          phone_number: state.phone!.toString(),
          subject: state.subjectSMS,
        }).then(async (data: ISMSResponse) => {
          if (data.detail == "SMS Sended") {
            state.pinSent = true;
            coreStore.nextHelpStep(2);
            resolve(data);
            await router.push({ name: "registerPinCode" });
          } else {
            state.pinSent = false;
          }
        });
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  };

  const sendPhoto = async (fileName: string, goBack: boolean) => {
    try {
      let user = registerStore.user;
      if (coreStore.selectedUser !== null) {
        user = coreStore.selectedUser;
      }

      const split = state.photo.split(";");
      const newPhoto = `${split[0]};filename=${fileName};${split[1]}`;
      handlerUploadPhoto(newPhoto, user.id).then(async (data) => {
        if (data.id) {
          if (coreStore.selectedUser == null) {
            handleMe().then(async (data: IUser) => {
              registerStore.setUser({
                user: data,
              });
              if (data.image_id) {
                getPhoto(data.id, data.image_id, true).then((data) => {
                  registerStore.setUserPhoto(data.url);
                  registerStore.setUserSelectedPhoto("");
                  coreStore.setRefreshUserLocation(true);
                });
              }
            });
          } else {
            getPhoto(user.id, data.id, true).then((data) => {
              registerStore.setUserPhoto("");
              registerStore.setUserSelectedPhoto(data.url);
              coreStore.setRefreshUserLocation(true);
            });
          }

          if (!goBack) {
            await router.push({ name: "joinHome" });
          } else {
            router.back();
          }
        }
      });
      state.photoSent = true;
      if (goBack) {
        state.isGoBack = true;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const sendLocalePhoto = async (fileName: string) => {
    try {
      let locale: ILocation | null = null;
      if (coreStore.selectedLocale !== null) {
        locale = coreStore.selectedLocale;
      } else {
        locale = registerStore.location;
      }

      const split = state.photo.split(";");
      const newPhoto = `${split[0]};filename=${fileName};${split[1]}`;
      await handlerUploadLocalePhoto(newPhoto, locale.id!).then((data) => {
        const imageId = data.id;
        getLocation(registerStore.location.id ?? -1).then(
          async (data: ILocation[] | undefined) => {
            if (data) {
              const location = data[0] as ILocation;
              registerStore.setLocation(location);
              if (imageId) {
                const photoUrl = await getLocalePhoto(
                  locale!.id!,
                  imageId,
                  true
                );
                if (photoUrl.url) {
                  registerStore.setLocalePhoto(photoUrl.url);
                }
              }
            }
            coreStore.setRefreshLocation(true);
            coreStore.setSelectedLocale(null);
          }
        );
      });
      state.photoSent = true;
    } catch (e) {
      console.error(e);
    }
  };

  const getPhoto = async (userId: number, imageId: number, url: boolean) => {
    const data = await handlerGetPhoto(userId, imageId, url);
    return data;
  };

  const getPhotoUrl = async (
    userId: number,
    imageId: number,
    url: boolean
  ): Promise<string> => {
    const data = await handlerGetPhoto(userId, imageId, url);
    return data.url;
  };

  const getLocalePhoto = async (
    localeId: number,
    imageId: number,
    url: boolean
  ) => {
    const data = await handlerGetLocalePhoto(localeId, imageId, url);
    return data;
  };

  const sendPin = async () => {
    try {
      handleSendUserInfo({
        name: registerStore.firstName,
        last_name: registerStore.lastName,
        phone_number: registerStore.phone.toString(),
        prefix_number: registerStore.phonePrefix,
        role: ROLES.EMPLOYEE,
        lang: "es",
        password: registerStore.password,
        status: "active",
        code: state.pin,
        device_id: tokenId.value,
        is_onboarding: true,
        gtm: getTimeZone(),
      }).then(async (data: ILoginResponse) => {
        if (data.access_token) {
          state.infoSent = true;
          registerStore.setUser(data);
          coreStore.nextHelpStep(3);
          registerStore.setPin({
            pin: state.pin,
          });
          await router.push({ name: "registerEmployeePhoto" });
        } else {
          state.infoSent = false;
        }
      });

      state.pinSent = !!state.pinCodeValid;
    } catch (e) {
      state.pinSent = false;
      console.error(e);
    }
  };

  const savePin = async () => {
    try {
      registerStore.setPin({
        pin: state.pin,
      });
      state.pinSent = true;
      await router.push({ name: "registerPassword" });
    } catch (e) {
      state.pinSent = false;
      console.error(e);
    }
  };

  const sendOwnerInfo = async () => {
    try {
      registerStore.setPassword({
        password: state.password,
      });

      handleSendUserInfo({
        name: registerStore.firstName,
        last_name: registerStore.lastName,
        phone_number: registerStore.phone.toString(),
        prefix_number: registerStore.phonePrefix,
        role: ROLES.OWNER,
        email: registerStore.email.toString(),
        birthdate: registerStore.birthday,
        lang: "es",
        password: registerStore.password,
        status: "active",
        code: registerStore.pin,
        device_id: tokenId.value,
        gtm: getTimeZone(),
        is_owner: true,
        is_onboarding: true,
      }).then(async (data: ILoginResponse) => {
        if (data.access_token) {
          state.infoSent = true;
          registerStore.setUser(data);
          coreStore.nextHelpStep(4);
          await router.push({ name: "registerPlan" });
        } else {
          state.infoSent = false;
        }
      });

      state.pinSent = state.pinCodeValid;
    } catch (e) {
      state.pinSent = false;
      console.error(e);
    }
  };

  const setNotifications = async () => {
    try {
      const data: INotificationNumber[] = await handlerNotification();
      registerStore.setStoreNotifications(data);
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const updateOwnerInfo = async (id: number) => {
    try {
      handlePutModel({
        model: MODEL.USER,
        id: id,
        fields: {
          name: state.name,
          last_name: state.last_name,
          email: state.email,
          birthdate: state.birthday,
        },
      }).then(async (data: IUser) => {
        registerStore.setUser({
          user: data,
        });
        const toast = await toastController.create({
          message: i18n.global.t("global.profileUpdated"),
          duration: 1500,
          position: "bottom",
          mode: "ios",
          icon: "tick-circle",
        });
        await toast.present();
      });
    } catch (e) {
      console.error(e);
    }
  };

  const updateManagerInfo = async (id: number) => {
    try {
      handlePutModel({
        model: MODEL.USER,
        id: id,
        fields: {
          name: state.name,
          last_name: state.last_name,
          email: state.email,
        },
      }).then(async (data: IUser) => {
        registerStore.setUser({
          user: data,
        });
        const toast = await toastController.create({
          message: i18n.global.t("global.updateSuccess"),
          duration: 1500,
          position: "bottom",
          mode: "ios",
          icon: "tick-circle",
        });
        await toast.present();
      });
    } catch (e) {
      console.error(e);
    }
  };

  const updateEmployeeInfo = async (id: number) => {
    try {
      handlePutModel({
        model: MODEL.USER,
        id: id,
        fields: {
          name: state.name,
          email: state.email,
          last_name: state.last_name,
        },
      }).then(async (data: IUser) => {
        state.infoSent = true;
        registerStore.setUser({
          user: data,
        });
        const toast = await toastController.create({
          message: i18n.global.t("global.profileUpdated"),
          duration: 1500,
          position: "bottom",
          mode: "ios",
          icon: "tick-circle",
        });
        await toast.present();
      });
    } catch (e) {
      state.infoSent = false;
      console.error(e);
    }
  };

  const saveEntityInfo = async () => {
    try {
      registerStore.setEntityInfo({
        name: state.name,
        commercial_name: state.commercial_name,
        address: state.address,
        vat: state.vat,
        city_id: state.city_id,
        country_id: state.country_id,
      });
      state.infoEntitySaved = true;
      await router.push({ name: "registerBusinessInfoFinalStep" });
    } catch (e) {
      state.infoEntitySaved = false;
      console.error(e);
    }
  };

  const sendEntityInfo = async () => {
    try {
      handlePostModel({
        model: "entity",
        fields: {
          name: registerStore.entity_name,
          commercial_name: registerStore.commercial_name,
          cif: registerStore.cif,
          vat: registerStore.vat,
          number_employees: registerStore.number_employees,
          number_locals: registerStore.number_locals,
          address: registerStore.address,
          city_id: registerStore.city_id,
          country_id: registerStore.country_id,
          email: state.email,
          phone_number: state.phone,
          owner_id: registerStore.user.id,
        },
      }).then(async (data: IEntityResponse) => {
        if (data.id) {
          state.infoSent = true;
          coreStore.resetHelpStep();

          await handleGetMeAndGetLocation().then(async () => {
            const { verifySpeaking } = useAudio();
            await verifySpeaking();
            setTimeout(async () => {
              router.go(0);
            }, 1000);
            await router.push({ name: "admin.home" });
          });
        } else {
          state.infoSent = false;
        }
      });

      state.pinSent = !!state.pinCodeValid;
    } catch (e) {
      state.pinSent = false;
      console.error(e);
    }
  };

  const updateEntityInfo = async (id: string) => {
    try {
      handlePutModel({
        id: id,
        model: "entity",
        fields: {
          name: state.name,
          commercial_name: state.commercial_name,
          vat: state.vat,
          number_employees: state.number_employees,
          number_locals: state.number_locals,
          address: state.address,
          city_id: state.city_id,
          country_id: state.country_id,
          email: state.email,
          phone_number: state.phone,
        },
      }).then(async (data: IEntityResponse) => {
        const toast = await toastController.create({
          message: i18n.global.t("global.dataUpdated"),
          duration: 1500,
          position: "bottom",
          mode: "ios",
          icon: "tick-circle",
        });
        await toast.present();
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleGetMe = async () => {
    return handleMe().then(async (data: IUser) => {
      registerStore.setUser({
        user: data,
      });
    });
  };

  const handleGetMeAndGetLocation = async () => {
    handleMe().then(async (data: IUser) => {
      registerStore.setUser({
        user: data,
      });
      let location = null;
      location = await getLocation(data.location_id ?? -1);
      if (location) {
        registerStore.setLocation(location[0]);
      }
    });
  };

  const sendInvitationLink = async (
    phoneNumber: string,
    locationCode: string
  ) => {
    try {
      const result = await handlerSendInvitation(phoneNumber, locationCode);
      return result;
    } catch (e) {
      state.pinSent = false;
      console.error(e);
    }
  };

  return {
    ...toRefs(state),
    saveInfo,
    saveOwnerInfo,
    sendOwnerInfo,
    updateOwnerInfo,
    updateManagerInfo,
    updateEmployeeInfo,
    sendPin,
    savePin,
    sendPhoto,
    sendLocalePhoto,
    setUser: registerStore.setUser,
    setLocation: registerStore.setLocation,
    clearUserStore: registerStore.clearUserStore,
    setUserPhoto: registerStore.setUserPhoto,
    setUserSelectedPhoto: registerStore.setUserSelectedPhoto,
    setLocalePhoto: registerStore.setLocalePhoto,
    user,
    notifications,
    userPhoto,
    userSelectedPhoto,
    localePhoto,
    saveEntityInfo,
    sendEntityInfo,
    updateEntityInfo,
    getPhoto,
    getLocalePhoto,
    handleGetMe,
    sendInvitationLink,
    setNotifications,
  };
}
