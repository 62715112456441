export const verifyInputPassword = (type: string) => {
  return ["password"].includes(type);
};

export const verifyInputTextAndNumber = (type: string) => {
  return ["text", "number", "float", "email"].includes(type);
};

export const verifyInputTel = (type: string) => {
  return ["tel"].includes(type);
};

export const verifyInputDate = (type: string) => {
  return ["date"].includes(type);
};

export const verifyInputCheckbox = (type: string) => {
  return ["checkbox"].includes(type);
};

export const verifyInputToggle = (type: string) => {
  return ["toggle"].includes(type);
};

export const verifyInputSwitch = (type: string) => {
  return ["switch"].includes(type);
};

export const verifyInputSelect = (type: string) => {
  return ["select", "multiple"].includes(type);
};

export const verifyInputTextArea = (type: string) => {
  return ["textarea"].includes(type);
};

export const verifyInputRadio = (type: string) => {
  return ["radio"].includes(type);
};

export const verifyInputPin = (type: string) => {
  return ["pin"].includes(type);
};

export const verifyInputAutocomplete = (type: string) => {
  return ["autocomplete"].includes(type);
};
