import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { IonicVue } from "@ionic/vue";
import { vsxIcon } from "vue-iconsax";
import { installDirectives } from "@/directives";
import VueHighlightJS from "vue3-highlightjs";
import i18n from "@/plugins/i18n";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-advanced-cropper/dist/style.css";
import timeAgo from "vue-timeago3";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import "highlight.js/styles/atom-one-dark.css";
import "@ionic/vue/css/core.css";

import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

import "./theme/variables.css";

import "./theme/custom.css";
import "./theme/wehop-shepherd.css";
import pinia from "@/plugins/pinia";
import { BackgroundTask } from "@capawesome/capacitor-background-task";
import { Capacitor } from "@capacitor/core";

const app = createApp(App);

app.component("v-select", vSelect);
app.component("VsxIcon", vsxIcon);
app.component("VueDatePicker", VueDatePicker);
app.use(IonicVue);
app.use(router);
app.use(timeAgo);
app.use(VueHighlightJS);
app.use(pinia);
app.use(i18n);
installDirectives(app);

router.isReady().then(async () => {
  if (Capacitor.isNativePlatform()) {
    await BackgroundTask.beforeExit(async () => {
      app.mount("#app");
    });
  } else {
    app.mount("#app");
  }
});
