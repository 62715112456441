import {MODEL} from "@/utils/constants";
import {handleSearchReadModel} from "@/services/generic";


export function useUser() {

    const getUserInfo = async (userId: number) => {
        const payloadUser = {
            model: MODEL.USER,
            fields: ["id", "first_name", "last_name", "username", "email", "phone", "image_id"],
        };
        const filtersUser = [
            {
                field: "id",
                op: "==",
                value: userId,
            },
        ];
        return await handleSearchReadModel(
            payloadUser,
            filtersUser
        );
    };

    return {
        getUserInfo,
    };
}
